import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Dashboard from '../container/dashboard/index';
import AdminUsers from '../container/admin-users/index';
import Leads from '../container/leads';
import Pipelines from '../container/pipelines';
import Kanban from '../container/kanban/Index';
import List from "../container/list/list";
import BlockedLeads from "../container/blockleads/index";
import Groups from "../container/groups-and-posts/index";
import BackgroundJobs from "../container/background-jobs/index";
import Settings from "../container/settings/index";
import Profile from "../container/userProfile/index";

import PrivateRoute from './private-route';
import DashboardLayout from '../layout/dashboardLayout';

import { setAuthToken } from '../config/axios/axios-configuration';
import Template from '../container/Templates/index';

const Index = () => {
  const { token } = useSelector(state => state.auth);
  setAuthToken(token);
  
  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/dashboard' />
      </Route>
      <PrivateRoute path='/dashboard' component={Dashboard} withLayout={DashboardLayout}/>
      <PrivateRoute path='/chatpro-users' component={AdminUsers} withLayout={DashboardLayout}/>
      <PrivateRoute path='/pipelines/:id' component={Kanban} withLayout={DashboardLayout}/>
      <PrivateRoute path='/leads' component={Leads} withLayout={DashboardLayout}/>
      <PrivateRoute path='/pipelines' component={Pipelines} withLayout={DashboardLayout}/>
      <PrivateRoute path='/lists' component={List} withLayout={DashboardLayout}/>
      <PrivateRoute path='/blocked-leads' component={BlockedLeads} withLayout={DashboardLayout}/>
      <PrivateRoute path='/groups-posts' component={Groups} withLayout={DashboardLayout}/>
      <PrivateRoute path='/messaging' component={Template} withLayout={DashboardLayout}/>
      <PrivateRoute path='/background-jobs' component={BackgroundJobs} withLayout={DashboardLayout}/>
      <PrivateRoute path='/settings' component={Settings} withLayout={DashboardLayout}/>
      <PrivateRoute path='/profile' component={Profile} withLayout={DashboardLayout}/>
      <Redirect from='*' to='/' />
    </Switch>
  )
}

export default DashboardLayout(Index);
