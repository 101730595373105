import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { ChartContainer } from '../../container/leads/style';

const ChartjsAreaChart = props => {
  const { labels, datasets, options, layout, id } = props;
  const data = {
    labels,
    datasets,
  };

  return (
    <div>
      <ChartContainer className="parentContainer">
        <Line
          id={id}
          data={data}
          height={117}
          options={{
            ...options,
            ...layout,
          }}
        />
      </ChartContainer>
    </div>
  );
};

ChartjsAreaChart.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  layout: PropTypes.object,
  options: PropTypes.object,
  id: PropTypes.string,
};

export {
  ChartjsAreaChart
};
