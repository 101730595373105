import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual, isEmpty } from 'lodash';
import moment from 'moment';
import { Link } from "react-router-dom";
import { useDrop } from "react-dnd";
import { Scrollbars } from "react-custom-scrollbars";
import { useDrag } from "react-dnd";

import {
  Input as AntInput,
  Space,
  Spin,
  Tooltip,
  DatePicker,
  Divider,
  Select,
  Form,
  Col,
  Row,
  Tag
} from "antd";
import propTypes from "prop-types";
import FeatherIcon from 'feather-icons-react';

import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { MdOutlineIosShare } from 'react-icons/md'
import { CloseCircleOutlined } from "@ant-design/icons"
import Facebook from "../../static/img/facebook.svg";
import Messenger from "../../static/img/messenger.svg";
import { FaTimes } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import NoUser from "../../static/img/no-user.png";

import { BiPencil } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { AiOutlinePlusCircle } from "react-icons/ai";

import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';

import KanbanBoardItem from "./overview/KanbanBoardItem";
import { validateEmail, isValidURL, parseData, orderArray, sortArray } from "../../utils/constants";
import { Dropdown } from "../../components/dropdown/dropdown";
import Input from '../../components/input/input';
import { Modal } from "../../components/modals/antd-modals";
import { Button } from "../../components/buttons/buttons";

import {
  GetStageLeads,
  UpdateStageRecord,
  SetState,
  DeleteStageRecord,
  RemoveAllStageLeads,
  AddLeadToStage,
  RemoveStageLead
} from "../../redux/slices/stages-slice";

import {
  DeleteLeadsData,
  BlockLeadsData,
} from "../../redux/slices/leads-slice";

import {
  SetState as SetLeadsState,
  UpdateLeadsRecord,
  UpdateLeadsField
} from "../../redux/slices/leads-slice";

import {
  GetPipelineStages,
  SetState as SetPipelineState
} from "../../redux/slices/pipeline-slice";

import {
  SetState as SetDashboardState,
} from "../../redux/slices/dashboard-slice";

const { TextArea } = AntInput;

const PipelineColumn = ({
  stage,
  keyword
}) => {
  const dispatch = useDispatch();

  const {
    dashboard: {
      success: dashboardSuccess
    },
    list: {
      lists,
      tags
    },
    stages: {
      err,
      stageLeadsLoading,
      stages,
      success,
      loading,
      stageLeads,
      reloadLeads
    },
    pipelines: {
      selectedPipelineData: pipelineId,
      placeAtTop,
      pipelinesData,
      stages: pipelineStages
    },
    leads: {
      loading: leadsLoading,
      success: leadsSuccess,
      err: leadsError,
      fieldEdited
    }
  } = useSelector(state => state);

  const [pipelineStageData, setPipelineStageData] = useState({
    pipelineId: null,
    stageId: null,
    overwriteStages: true,
  });

  const [leadAction, setLeadAction] = useState();


  const getLeadsArray = () => {
    let currentStageLeads = stageLeads?.filter((lead) => lead?.stageId === stage?._id) || [];
    let uniqueLeads = new Map();
  
    currentStageLeads.forEach((lead) => {
      // Using leadId as the key in a Map to ensure uniqueness
      uniqueLeads.set(lead.leadId, lead);
    });
  
    let uniqueLeadsArray = Array.from(uniqueLeads.values());
  
    if (placeAtTop && leadAction === 'changeTaskStatus') {
      return orderArray({ array: uniqueLeadsArray, label: 'placedAt', type: 'desc' });
    } else {
      return uniqueLeadsArray.reverse();
    }
  };

  const [filter, setFilter] = useState({ keyword , placeAtTop, skip: 0, limit: 10, stageId: stage._id });
  const scrollRef = useRef(null);
  let scrollValue = 0;
  let scrollTopValue = 0;
  let stateScrollTop = useRef(0);
  const [lead, setLead] = useState({});
  const [deleteStageLeads, setDeleteStageLeads] = useState(false);
  const [editStageName, setEditStageName] = useState(false);
  const [deleteStageModal, setDeleteStageModal] = useState(false);
  const [deleteLeadModal, setDeleteLeadModal] = useState(false);
  const [editLeadModal, setEditLeadModal] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [editLink, setEditLink] = useState(false);
  const [editAddLink, setAddLink] = useState(false);
  const [linkValue, setLinkValue] = useState("");
  const [leadUpdated, setLeadUpdated] = useState({});
  const [blockLoading, setBlockLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [currentLeads, setCurrentLeads] = useState([])

  const handleGetStageLeads = (filter) => {
    return dispatch(GetStageLeads(filter));
  }

  useEffect(() => {
    if (reloadLeads) {
      handleGetStageLeads(filter);
      dispatch(SetState({ field: 'reloadLeads', value: false }));
    }
  }, [reloadLeads])

  useEffect(() => {
    if (success) {
      dispatch(SetState({ field: 'success', value: false }))
    }
    if (err) {
      dispatch(SetState({ field: 'err', value: false }))
    }
    setDeleteStageModal(false);
    setDeleteLeadModal(false);
    setDeleteStageLeads(false);
  }, [success, err, dashboardSuccess])

  useEffect(() => {
    if (leadsSuccess) {
      dispatch(SetLeadsState({ field: 'success', value: false }));
    }
    if (leadsError) {
      dispatch(SetLeadsState({ field: 'err', value: false }));
    }
    setEditLeadModal(false);
    setPipelineStageData({});
  }, [leadsSuccess, leadsError])

  useEffect(() => {
    if (stageLeads){
     setCurrentLeads(getLeadsArray())
    }
   
  }, [stageLeads])

  const changeTaskStatus = async (lead, stageId) => {
    const { _id: leadId } = lead;
    const stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
    let oldStageId = null;
    stageLeadsData.forEach(data => {
      if (data._id === leadId) {
        oldStageId = data.stageId;
        data.stageId = stageId
        data.placedAt = moment().format()
      }
    });

    const stagesData = JSON.parse(JSON.stringify(stages));
    stagesData.forEach(stage => {
      if (stage._id === oldStageId) {
        stage.activeLeads -= 1
      }
      if (stage._id === stageId) {
        stage.activeLeads += 1
      }
    });

    dispatch(SetState({ field: 'stageLeads', value: stageLeadsData }));
    dispatch(SetState({ field: 'stages', value: stagesData }));

    await dispatch(AddLeadToStage({ pipelineId, stageId, leadId }));
    await  dispatch(GetStageLeads(filter));
    setLeadAction('changeTaskStatus')
  }

  const handleDeleteLeadFromPipeline = async () => {
    const { _id: stageId } = stage;
    const { _id: leadId } = lead;
    dispatch(RemoveStageLead({ leadId, stageId }))

    let stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
    stageLeadsData = stageLeadsData.filter(data => data._id !== leadId);

    let stagesData = JSON.parse(JSON.stringify(stages));
    stagesData.forEach(data => {
      if (data._id === stage._id) {
        data.activeLeads -= 1;
      }
    });

    dispatch(SetState({ field: 'stageLeads', value: stageLeadsData }))
    dispatch(SetState({ field: 'stages', value: stagesData }))
  }

  const handleUpdateStageRecord = async (stageId, value) => {
    if (!value || !value.trim) return dispatch(SetState({ field: "err", value: "Please Enter A Proper Name" }));
    const stagesData = JSON.parse(JSON.stringify(stages));

    const alreadyExists = stagesData.find(data => data?.name === value && data?._id !== stageId);
    if (alreadyExists) return dispatch(SetState({ field: "err", value: "Stage With This Name Already Exists" }));

    stagesData.forEach(stage => {
      if (stage._id === stageId) {
        stage.name = value;
      }
    });
    dispatch(SetState({ field: 'stages', value: stagesData }))
    await dispatch(UpdateStageRecord({ stageId, label: "name", data: value?.trim(), pipelineId }));
    setEditStageName(false);
  }

  const changeCardPosition = async ({ lead, stageId, position }) => {
    const { _id: leadId } = lead;
    const index = stages.findIndex((data) => data?._id === stageId);
    let newStageId;
    if (position === "start") {
      if (index === 0) return;
      newStageId = stages[0]?._id;
    } else if (position === "end") {
      if (index === stages.length - 1) return;
      newStageId = stages[stages.length - 1]?._id;
    }

    const stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
    let oldStageId = null;
    stageLeadsData.forEach(data => {
      if (data._id === leadId) {
        oldStageId = data.stageId;
        data.stageId = newStageId;
        data.placedAt = new Date()
      }
    });

    const stagesData = JSON.parse(JSON.stringify(stages));
    stagesData.forEach(stage => {
      if (stage._id === oldStageId) {
        stage.activeLeads -= 1
      }
      if (stage._id === newStageId) {
        stage.activeLeads += 1
      }
    });

    dispatch(SetState({ field: 'stageLeads', value: stageLeadsData }))
    dispatch(SetState({ field: 'stages', value: stagesData }))

    await dispatch(AddLeadToStage({ pipelineId, stageId: newStageId, leadId }));
  };

  const handleDeleteStageRecord = async (stageId) => {
    dispatch(DeleteStageRecord({ stageId })).then(() => {
      const stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
      const stagesData = JSON.parse(JSON.stringify(stages));
      const newStages = stagesData.filter(data => data._id !== stage._id);
      const newStageLeads = stageLeadsData.filter(data => data.stageId !== stage._id);
      dispatch(SetState({ field: 'stages', value: newStages }))
      dispatch(SetState({ field: 'stageLeads', value: newStageLeads }))
    });
  };

  const handleDeleteStageLeads = async () => {
    const { _id: stageId } = stage;
    let stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
    stageLeadsData = stageLeadsData.filter(data => data.stageId !== stageId);

    const stagesData = JSON.parse(JSON.stringify(stages));
    stagesData.forEach(data => {
      if (data._id === stageId) {
        data.activeLeads = 0;
      }
    });

    dispatch(SetState({ field: 'stageLeads', value: stageLeadsData }))
    dispatch(SetState({ field: 'stages', value: stagesData }))

    dispatch(RemoveAllStageLeads({ pipelineId, stageId }))
  };

  const handleDeleteLeadsData = async () => {
    try {
      let leads = [];
      const { _id: leadId } = lead;
      setDeleteLoading(true)
       leads.push(leadId)
      let stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
      stageLeadsData = stageLeadsData.filter(data => data._id !== leadId);
  
      let stagesData = JSON.parse(JSON.stringify(stages));
      stagesData.forEach(data => {
        if (data._id === stage._id) {
          data.activeLeads -= 1;
        }
      });
  
  
      await dispatch(DeleteLeadsData({ leadIds: leads }));
  
      dispatch(SetState({ field: 'stageLeads', value: stageLeadsData }))
      dispatch(SetState({ field: 'stages', value: stagesData }))
      setDeleteLoading(false);
      setDeleteLeadModal(false)
    } catch (error) {
      setDeleteLoading(true)
    }
   
  };

  const handleBlockLeadsData = async () => {
    try {
    
      let leads = [];
      const { _id: leadId } = lead;
      const { _id: stageId } = stage;
      setBlockLoading(true)
       leads.push(leadId)
      let stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
      stageLeadsData = stageLeadsData.filter(data => data._id !== leadId);
  
      let stagesData = JSON.parse(JSON.stringify(stages));
      stagesData.forEach(data => {
        if (data._id === stage._id) {
          data.activeLeads -= 1;
        }
      });
      await dispatch(BlockLeadsData({ leadIds:leads,}));
      
      dispatch(SetState({ field: 'stageLeads', value: stageLeadsData }))
      dispatch(SetState({ field: 'stages', value: stagesData }))
      setBlockLoading(false)
      setDeleteLeadModal(false)
    } catch (error) {
      setBlockLoading(false);
    }
  
  };

  const onCalenderDateChange = async ({ event, lead }) => {
    const date = new Date(event._d)?.getTime();
    const leadData = { ...lead, leadDate: date };
    const { _id: leadId } = lead;
    const stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
    stageLeadsData.forEach(data => {
      if (data._id === leadId) {
        data.leadDate = date
      }
    });

    dispatch(SetState({ field: 'stageLeads', value: stageLeadsData }))
    await dispatch(UpdateLeadsRecord({ ...leadData }));
    return false;
  };

  const createRandomDate = (start, end) => {
    const randomDate = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );

    return moment(randomDate).format();
  };

  const changeStagePosition = async ({ position, stageId }) => {
    const selectedStageIndex = stages.findIndex(
      (stage) => stage._id === stageId
    );
    let start;
    let end;
    let disContinue = false;

    switch (position) {
      case "start":
        if (selectedStageIndex === 0) {
          disContinue = true;
          break;
        }
        const firstStageDate = new Date(stages[0]?.positionDate);
        start = new Date(firstStageDate);
        end = new Date(
          firstStageDate.getFullYear(),
          firstStageDate.getMonth(),
          firstStageDate.getDate() - 1
        );
        break;
      case "end":
        if (selectedStageIndex === stages.length - 1) {
          disContinue = true;
          break;
        }
        const lastStageDate = new Date(
          stages[stages.length - 1]?.positionDate
        );
        start = new Date(lastStageDate);
        end = new Date(
          lastStageDate.getFullYear(),
          lastStageDate.getMonth(),
          lastStageDate.getDate() + 1
        );
        break;
      case "left":
        if (selectedStageIndex < 1) {
          disContinue = true;
          break;
        } else if (selectedStageIndex === 1) {
          end = new Date(stages[selectedStageIndex - 1]?.positionDate);
          start = new Date(
            end.getFullYear(),
            end.getMonth(),
            end.getDate() - 1
          );

        } else if (selectedStageIndex > 1) {
          end = new Date(stages[selectedStageIndex - 1]?.positionDate);
          start = new Date(stages[selectedStageIndex - 2]?.positionDate);
        }
        break;
      case "right":
        if (selectedStageIndex === stages.length - 1) {
          disContinue = true;
          break;
        } else if (selectedStageIndex === stages.length - 2) {
          end = new Date(stages[selectedStageIndex + 1]?.positionDate);
          start = new Date(
            end.getFullYear(),
            end.getMonth(),
            end.getDate() + 1
          );
        } else if (selectedStageIndex < stages.length - 2) {
          end = new Date(stages[selectedStageIndex + 1]?.positionDate);
          start = new Date(stages[selectedStageIndex + 2]?.positionDate);
        }
        break;
    }

    if (disContinue) return;
    const extractedDate = createRandomDate(start, end);

    let stagesData = JSON.parse(JSON.stringify(stages));
    stagesData.forEach(data => {
      if (data._id === stage._id) {
        data.positionDate = new Date(extractedDate).toISOString();
      }
    });

    stagesData = orderArray({ array: stagesData, label: 'positionDate', type: 'asc' });
    dispatch(SetState({ field: 'stages', value: stagesData }));
    setEditStageName(false);

    dispatch(
      UpdateStageRecord({
        stageId,
        label: "positionDate",
        data: extractedDate,
        pipelineId,
      })
    )
      await  dispatch(GetStageLeads(filter));
  };

  const KanbanItem = ({ lead, children }) => {
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
      item: { type: "card", lead },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0 : 1;

    drag(ref);

    return (
      <div className="sDash_kanvan-task__single" ref={ref} style={{ opacity }}>
        {children}
      </div>
    );
  };

  const handleExportStage = async ({ stage }) => {
    await dispatch(UpdateStageRecord({
      stageId: stage._id,
      label: "exported",
      data: true,
      pipelineId,
    }));
    dispatch(SetDashboardState({ field: "success", value: 'Stage Has Been Exported' }))
  };

  const handleUpdateLead = async () => {
    const verifyEmail = validateEmail(lead.email);
    if (lead.email?.length && !verifyEmail) {
      return dispatch(SetLeadsState({ field: "messageError", value: "Email Is Invalid" }));
    }
    if (isEqual(lead, leadUpdated) && !pipelineStageData?.pipelineId) return dispatch(SetLeadsState({ field: "messageError", value: "Please Change Any Field To Update" }));

    const { _id: leadId } = lead;
    let stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
    let stagesData = JSON.parse(JSON.stringify(stages));
    const { pipelineId: selectedPipelineId, stageId: selectedStageId } = pipelineStageData;

    stageLeadsData = stageLeadsData.filter(data => data._id !== leadId);
    let leadData = lead;
    if (selectedPipelineId && selectedStageId) {
      if (stage._id !== selectedStageId && stages.find(stage => stage._id === selectedStageId)) {
        leadData = { ...leadData, stageId: selectedStageId }
        stagesData.forEach(data => {
          if (data._id === selectedStageId) data.activeLeads += 1;
          if (data._id === stage._id) data.activeLeads -= 1;
        })
      }
    }
    stageLeadsData.push(leadData);
    dispatch(SetState({ field: 'stageLeads', value: stageLeadsData }))
    dispatch(SetState({ field: 'stages', value: stagesData }))
    
    dispatch(UpdateLeadsRecord({ ...lead, ...pipelineStageData }));
    setLeadAction('save')
  };

  const changeLeadsRecord = ({ label, event }) => {
    if (label === "contact" && event.target.value > 999999999999) return;
    if (label === "link") {
      const alreadyExists = lead?.links.find((link) => link === linkValue);
      if (alreadyExists)
        return dispatch(
          SetLeadsState({ field: "messageError", value: "Link Is Already Added" })
        );
      if (!isValidURL(linkValue))
        return dispatch(
          SetLeadsState({ field: "messageError", value: "Please Enter A Valid URL" })
        );
      else {
        const links = [...lead.links, linkValue];
        return setLead({ ...lead, links })
      }
    }
    let value =
      label === "tags" || label === "lists" ? event : event?.target?.value;
    if (label === "leadsValue") {
      return setLead({ ...lead, [label]: { value, createdAt: new Date() } })
    }

    setLead({ ...lead, [label]: value })
  };

  const handleCancelEditLead = () => {
    if (fieldEdited) {
      const { _id: leadId } = lead;
      const stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
      stageLeadsData.forEach(stage => {
        const { leads = [] } = stage;
        for (let i = 0; i < leads.length; i += 1) {
          if (leads[i]._id === leadId) leads[i] = {
            ...leads[i],
            contact: lead.contact,
            email: lead.email,
            links: lead.links,
            leadsValue: lead.leadsValue
          };
        }
      });

      dispatch(SetState({ field: 'stageLeads', value: stageLeadsData }))
      dispatch(SetState({ field: 'fieldEdited', value: false }))
    }
    setLead({});
    setPipelineStageData({});
    setEditLeadModal(false);
    setEditEmail(false);
    setEditContact(false);
    setEditValue(false);
    setAddLink(false);
    setEditLink(false);
    setLinkValue("");
  }

  const handleImageError = (image) => {
    if (document.querySelector(`img[src="${image}"]`))
      document.querySelector(`img[src="${image}"]`).src = NoUser;
  };

  const isValidHexadecimalID = (id) => {
    const hexRegex = /^[0-9a-fA-F]{24}$/;
    return hexRegex.test(id);
  };

  const showLeadsTags = (tagIds) => {
    const tagData = [];
    tagIds?.map((tagId, index) => {
      const foundList = tags.find((data) => data._id === tagId);

      if (foundList) {
        tagData.push(foundList._id);
      }
      else {
        const notAnID = isValidHexadecimalID(tagId)
        if (!notAnID) {
          tagData.push(tagId);
        }
      }
    });
    
    return tagData;
  }

  const showLeadsLists = (listIds) => {
    const listsData = [];
    listIds?.map((listId, index) => {
      const foundList = lists.find((data) => data._id === listId);

      if (foundList) {
        listsData.push(foundList._id);
      }
      else {
        const notAnID = isValidHexadecimalID(listId)
        if (!notAnID) {
          listsData.push(listId);
        }

      }
    });

    return listsData;
  };

  const removeLeadsLink = (value) => {
    const links = lead?.links?.filter((link) => link !== value);
    setLead({ ...lead, links });
    dispatch(UpdateLeadsField({ leadId: lead.leadId, label: 'links', value: links }));
  };

  const changeLeadsLink = ({ value, index }) => {
    const alreadyExists = lead?.links.findIndex((link) => link === value);
    if (alreadyExists !== -1 && alreadyExists !== index)
      return dispatch(
        SetLeadsState({ field: "messageError", value: "Link Is Already Added" })
      );
    if (value && !isValidURL(value)) {
      return dispatch(
        SetLeadsState({ field: "messageError", value: "Please Enter A Valid URL" })
      );
    }
    let links = parseData(JSON.stringify(lead?.links));
    if (!value) links = links.filter((link, ind) => ind !== index);
    else links[index] = value;
    setLead({ ...lead, links });
    setEditLink(false);
    dispatch(UpdateLeadsField({ leadId: lead.leadId, label: 'links', value: links }));
  };

  const handleGetPipelineStages = (value) => {
    if (!value) setPipelineStageData({
      pipelineId: null,
      stageId: null,
      overwriteStages: true,
    })
    dispatch(GetPipelineStages({ pipelineId: value }));
  };

  const handleOnScroll = (event) => {
    let { top, scrollTop } = event;
    stateScrollTop.current = scrollTop;
    if (top >= 0.99 && top > scrollValue && filter.skip < stage.activeLeads - 10) {
      scrollTopValue = scrollTop;
      dispatch(SetState({ field: 'stageLeadsLoading', value: stage._id }))
      const newFilter = { ...filter, skip: filter.skip + 10, limit: 10 }
      setFilter(newFilter)
      handleGetStageLeads({ ...newFilter }).then(() => {
        if (scrollRef.current?.view?.scrollTop || scrollRef.current?.view?.scrollTop === 0) scrollRef.current.view.scrollTop = scrollTopValue + 200;
      });
    }
    scrollValue = top;
  };

  const KanbanColumn = ({ status, changeTaskStatus, children }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
      accept: "card",
      drop({ lead }) {
        changeTaskStatus(lead, status);
      },
    });
    drop(ref);

    return (
      <div key={status} id={stage._id} className="sDash_kanban-board-item" ref={ref}>
        <Scrollbars
          key={`${stage._id}-scrollbar`}
          ref={scrollRef}
          onScrollFrame={(event) => { handleOnScroll(event) }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          className="sDash_kanban-board-item-scrolable"
        >
          {children}
        </Scrollbars>
      </div>
    );
  };

  const handleUpdateLeadsField = ({ label, value, leadId }) => {
    let newValue = value;
    if (label === 'email' && value?.length && !validateEmail(value)) {
      setLead({ ...lead, email: '' });
      return dispatch(
        SetLeadsState({ field: "messageError", value: "Email Is Invalid" })
      );
    }
    if (label === "leadsValue") newValue = { value, createdAt: new Date() };
    else if (label === "links") {
      const links = lead.links || [];
      const alreadyExists = links.find((link) => link === linkValue);
      if (alreadyExists) {
        setLinkValue("");
        return dispatch(
          SetLeadsState({ field: "messageError", value: "Link Is Already Added" })
        );
      }
      if (!isValidURL(linkValue)) {
        setLinkValue("");
        return dispatch(
          SetLeadsState({ field: "messageError", value: "Please Enter A Valid URL" })
        );
      }
      else newValue = [...links, linkValue];
      setAddLink(false);
      setLinkValue("");
    }
    setLead({ ...lead, [label]: newValue });
    let stageLeadsData = JSON.parse(JSON.stringify(stageLeads));
    stageLeadsData = stageLeadsData.filter(data => data.leadId !== leadId);
    stageLeadsData.push({ ...lead, [label]: newValue })

    dispatch(SetState({ field: "stageLeads", value: stageLeadsData }));
    dispatch(UpdateLeadsField({ leadId, label, value: newValue }));
  };

  KanbanColumn.propTypes = {
    status: propTypes.string,
    children: propTypes.array,
    changeTaskStatus: propTypes.func,
  };

  KanbanItem.propTypes = {
    id: propTypes.string,
    children: propTypes.object,
  };

  return (
    <React.Fragment>
      <KanbanColumn
        key={stage._id}
        status={stage._id}
        changeTaskStatus={changeTaskStatus}>
        {/* Stage Header Start */}
        <div className="header-wrapper d-flex threeDotswrapper">
          <h4 className="list-header-title">
            {editStageName ? <Input
              defaultValue={stage.name}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  handleUpdateStageRecord(stage._id, event.target.value)
                }
              }}
              onBlur={(event) => {
                handleUpdateStageRecord(stage._id, event.target.value)
              }}
              type="text"
              className="edit-column-name-input"
            /> : <span className="stage-title">{stage.name}</span>}
          </h4>
          <Dropdown
            content={
              <>
                <Link
                  className='d-flex dropdown-sub-menu-wrapper'
                  onClick={() => setEditStageName(true)}
                  to="#"
                >
                  <EditOutlined />
                  <span className='dropdown-text'>
                    Edit Name
                  </span>
                </Link>
                <Link
                  to="#"
                  onClick={() => changeStagePosition({ position: 'start', stageId: stage._id })}
                  className='d-flex dropdown-sub-menu-wrapper'
                >
                  <HiChevronDoubleLeft className="dropdown-icon" />
                  <span>
                    Move to
                    Start
                  </span>
                </Link>
                <Link
                  to="#"
                  onClick={() => changeStagePosition({ position: 'left', stageId: stage._id })}
                  className='d-flex dropdown-sub-menu-wrapper'
                >
                  <IoIosArrowBack className="dropdown-icon" />
                  <span>
                    Move to Left
                  </span>
                </Link>
                <Link
                  to="#"
                  onClick={() => changeStagePosition({ position: 'right', stageId: stage._id })}
                  className='d-flex dropdown-sub-menu-wrapper'
                >
                  <IoIosArrowForward className="dropdown-icon" />
                  <span >
                    Move to Right
                  </span>
                </Link>
                <Link
                  to="#"
                  onClick={() => changeStagePosition({ position: 'end', stageId: stage._id })}
                  className='d-flex dropdown-sub-menu-wrapper'
                >
                  <HiChevronDoubleRight className="dropdown-icon" />
                  <span>
                    Move to End
                  </span>
                </Link>
                <hr />
                {currentLeads?.length ? <Link
                  to="#"
                  onClick={() => { setDeleteStageLeads(true); }}
                >
                  <CloseOutlined />
                  <span>
                    Remove All Leads
                  </span>
                </Link> : ''}

                <Link
                  to="#"
                  onClick={() => { setDeleteStageModal(true) }}
                >
                  <DeleteOutlined />
                  <span>
                    Delete
                  </span>
                </Link>
                <Link
                  onClick={() => { handleExportStage({ stage, totalLeads: stageLeads?.length }) }}
                  to="#"
                >
                  <MdOutlineIosShare />
                  <span >
                    Export
                  </span>
                </Link>
              </>
            }
            action={["click"]}
            className="wide-dropdwon kanbanCard-more"
          >
            <Link to="#" className="btn-more">
              <FeatherIcon className="stages-column-dropdown-icon" icon="more-horizontal" size={20} />
            </Link>
          </Dropdown>
          <span className={editStageName ? "total-text-wrapper" : ' column-counter leads-counter'}>
            Total:
            <span className="number-of-count">
              {stage.activeLeads}
            </span>
          </span>
        </div>
        <div className="sDash_kanvan-task">
          {stageLeadsLoading === stage._id ? <Spin style={{ margin: '45%' }} size="small" />
            : currentLeads?.length
              ? currentLeads?.map((lead, index) => {
                return (
                  <KanbanItem
                    key={`${lead.leadId}`}
                    lead={lead}
                  >
                    <div id={`kanban-board-${index}`} key={lead._id}>
                      <KanbanBoardItem
                        leadData={lead}
                        setLead={setLead}
                        onCalenderDateChange={onCalenderDateChange}
                        setLeadUpdated={setLeadUpdated}
                        setEditLeadModal={setEditLeadModal}
                        changeCardPosition={changeCardPosition}
                        setDeleteLeadModal={setDeleteLeadModal}
                        stage={stage}
                      />
                    </div>
                  </KanbanItem>
                );
              })
              : ""}
        </div>
      </KanbanColumn>
      <Modal
        className="addEvent-modal crm-basic-modal"
        footer={null}
        type="primary"
        centered
        visible={deleteStageModal}
        width={440}
        closable={false}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <FiTrash2 alt="block-user" className="custom-delete-icon-modal" />
          <h2 className="mb-3 text-center color-dark stage-delete-label-wrapper">
            {"Are you sure you want to delete this stage?"}
          </h2>
          <p className="text-center fs-14 color-gray">
            Deleting a stage will also remove all leads in this stage
            from the pipeline.

          </p>
        </div>
        <div className="modal-footer d-flex justify-content-center">
          <Button
            size="default"
            outlined
            type="white"
            onClick={() => setDeleteStageModal(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteStageRecord(stage._id)}
            size="default"
            type="primary"
            className="ml-2 delete-btn"
          >
            {loading ? <Spin size="small" /> : "Delete"}
          </Button>
        </div>
      </Modal>
      <Modal
        className="addEvent-modal crm-basic-modal"
        footer={null}
        type="primary"
        centered
        visible={deleteLeadModal}
        width={462}
        closable={false}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <CloseCircleOutlined className="custom-delete-icon-modal" />
          <h2 className="mb-3 text-center color-dark modal-inner-heading">
            {"Are you sure you want to remove this lead?"}
          </h2>
          <p className="text-center fs-14 color-gray">
            Leads belonging to this stage will be removed and you will have to add them again.
          </p>
        </div>
        <div className="modal-footer d-flex justify-content-center flex-column align-items-center">
          <div className="leads-modal-items-wrapper">
            <Button
              onClick={handleBlockLeadsData}
              size="default"
              type="primary"
              className=""
            >
              {blockLoading ? <Spin size="small" /> : 'Block'}
            </Button>
            <Button
              onClick={handleDeleteLeadsData}
              size="default"
              type="primary"
              className=""
            >
              {deleteLoading ? <Spin size="small" /> : 'Delete'}
            </Button>
            <Button
              onClick={handleDeleteLeadFromPipeline}
              size="default"
              type="primary"
              className=""
            >
              {loading ? <Spin size="small" /> : 'Remove'}
            </Button>
          </div>
          <Button
            onClick={() => setDeleteLeadModal(false)}
            size="default"
            outlined
            type="white"
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        className="addEvent-modal edit-lead-modal pipeline-edit-modal"
        footer={null}
        type="primary"
        title="Edit Lead"
        visible={editLeadModal}
        closable={true}
        width={722}
        onCancel={() => {
          setEditLeadModal(false)
        }}

      >
        <div className="d-flex justify-content-between align-items-start pipeline-stages-header-item">
          <div className="d-flex user-picture">
            <img
              onError={() => {
                handleImageError(lead.image);
              }}
              width={80}
              height={80}
              src={lead.image || NoUser}
              alt="no-user"
            />
            <div className="user-info d-flex flex-column justify-content-end pl-3">
              <h3 className="mb-0">{lead.name}</h3>
              <span className="d-flex align-items-baseline condition-wrapper-modal">
                {editEmail ? (
                  <Input
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        setEditEmail(false);
                        handleUpdateLeadsField({
                          leadId: lead.leadId,
                          label: "email",
                          value: event.target.value,
                        });
                      }
                    }}
                    onBlur={(event) => {
                      setEditEmail(false);
                      handleUpdateLeadsField({
                        leadId: lead.leadId,
                        label: "email",
                        value: event.target.value,
                      });
                    }}
                    className="condition-input-wrapper"
                    type="text"
                    onChange={(event) => {
                      changeLeadsRecord({
                        label: "email",
                        event,
                      });
                    }}
                    onClick={() => { setEditContact(false); setEditValue(false); setEditEmail(true); }}
                    placeholder="Add/Edit Email"
                    value={lead.email}
                  />
                ) : (
                  <>
                    {!lead.email && (
                      <span
                        onClick={() => { setEditContact(false); setEditValue(false); setEditEmail(true) }}
                        className="plus-icon-modal cursor-pointer"
                      >
                        +
                      </span>
                    )}
                    <span
                      onClick={() => { setEditContact(false); setEditValue(false); setEditEmail(true) }}
                      className="email"
                    >
                      {lead.email || "Add Email"}
                    </span>
                  </>
                )}
                {lead.email && (
                  <BiPencil
                    onClick={() => { setEditContact(false); setEditValue(false); setEditEmail(true) }}
                    className="pencil-icon"
                  />
                )}
              </span>
              <span className="d-flex align-items-baseline condition-wrapper-modal">
                {editContact ? (
                  <Input
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        setEditContact(false);
                        handleUpdateLeadsField({
                          leadId: lead.leadId,
                          label: "contact",
                          value: event.target.value,
                        });
                      }
                    }}
                    onBlur={(event) => {
                      setEditContact(false);
                      handleUpdateLeadsField({
                        leadId: lead.leadId,
                        label: "contact",
                        value: event.target.value,
                      });
                    }}
                    onChange={(event) => {
                      changeLeadsRecord({
                        label: "contact",
                        event,
                      });
                    }}
                    onClick={() => { setEditValue(false); setEditEmail(false); setEditContact(true) }}
                    className="phone-condition-input-wrapper"
                    type="number"
                    placeholder="Add/Edit Contact"
                    value={lead.contact}
                  />
                ) : (
                  <>
                    {!lead.contact && (
                      <span
                        onClick={() => { setEditValue(false); setEditEmail(false); setEditContact(true) }}
                        className="plus-icon-modal cursor-pointer"
                      >
                        +
                      </span>
                    )}{" "}
                    <span
                      onClick={() => { setEditValue(false); setEditEmail(false); setEditContact(true) }}
                      className="email-phone"
                    >
                      {lead.contact || "Add Contact"}
                    </span>
                  </>
                )}
                {lead.contact && (
                  <BiPencil
                    onClick={() => { setEditValue(false); setEditEmail(false); setEditContact(true) }}
                    className="pencil-icon"
                  />
                )}
              </span>
              <span className="d-flex align-items-baseline condition-wrapper-modal stages-overlay-wrapper flex-wrap">
                {lead?.links?.map((link, index) => {
                  return (
                    <>
                      {editLink === index ? (
                        <Input
                          key={index}
                          onKeyUp={(event) => {
                            if (event.key === "Enter") {
                              changeLeadsLink({
                                value: event.target.value,
                                index,
                              });
                            }
                          }}
                          onBlur={(event) => {
                            changeLeadsLink({
                              value: event.target.value,
                              index,
                            });
                          }}
                          className="social-condition-input-wrapper "
                          type="text"
                          placeholder="Edit Link"
                          defaultValue={lead.links[index]}
                        />
                      ) : (
                        <div className="link-properties d-flex align-items-center">
                          <a
                            className="email-wrapper d-flex"
                            target="_blank"
                            href={`${link}/${lead.leadId}`}
                          >
                            <span key={index} className="email-wrapper">
                              {link}
                            </span>
                          </a>{" "}
                          <span
                            style={{ marginLeft: "3px" }}
                            className="icons-wrapper-space"
                          >
                            <BiPencil
                              onClick={() => setEditLink(index)}
                              className="pencil-icon"
                            />
                          </span>
                          <FeatherIcon
                            className="trash-icon-wrapper"
                            onClick={() => {
                              removeLeadsLink(link);
                            }}
                            icon="trash-2"
                            size={12}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
                {!lead?.links?.length && (
                  <>
                    <span
                      onClick={() => setAddLink(true)}
                      className="plus-icon-modal add-email-plus-icon"
                    >
                      +
                    </span>
                    <span
                      onClick={() => setAddLink(true)}
                      className="add-link-wrapper"
                    >
                      Add Link
                    </span>
                  </>
                )}
                {lead?.links?.length ? (
                  <AiOutlinePlusCircle
                    onClick={() => setAddLink(true)}
                    className="add-link-wrapper plus-icon-wrap add-link-icon-wrapper"
                  />
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <div className="d-flex flex-column cost-and-socail-wrapper">
            <div
              className={
                editValue ? "cost-wrapper" : "cost-condition-text-controle"
              }
            >
              <span
                className={
                  lead.leadsValue?.value
                    ? "value-text-wrap"
                    : "new-condition-wrapper"
                }
              >
                Value:
                {editValue ? (
                  <Input
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        setEditValue(false);
                        handleUpdateLeadsField({
                          leadId: lead.leadId,
                          label: "leadsValue",
                          value: event.target.value,
                        });
                      }
                    }}
                    onBlur={(event) => {
                      setEditValue(false);
                      handleUpdateLeadsField({
                        leadId: lead.leadId,
                        label: "leadsValue",
                        value: event.target.value,
                      });
                    }}
                    onChange={(event) => {
                      changeLeadsRecord({
                        label: "leadsValue",
                        event,
                      });
                    }}
                    value={lead.leadsValue?.value}
                    className="cost-condition-input-wrapper"
                    type="number"
                    placeholder="Add/Edit Value"
                  />
                ) : (
                  <>
                    {!lead.leadsValue?.value && (
                      <span
                        onClick={() => { setEditEmail(false); setEditContact(false); setEditValue(true) }}
                        className="plus-icon-modal add-email-plus-icon cursor-pointer"
                      >
                        +
                      </span>
                    )}
                    <Tooltip title={lead.leadsValue?.value}>
                      <span
                        onClick={() => { setEditEmail(false); setEditContact(false); setEditValue(true) }}
                        className="cost-text-wrap"
                      >
                        {lead.leadsValue?.value || "Add Value"}
                      </span>
                    </Tooltip>
                  </>
                )}
                {lead.leadsValue?.value && (
                  <BiPencil
                    onClick={() => { setEditEmail(false); setEditContact(false); setEditValue(true) }}
                    className="pencil-icon"
                  />
                )}
              </span>
            </div>
            <div className="facebook-icon-wrap d-flex justify-content-end">
              <a
                href={`https://www.facebook.com/${lead.leadId}?leadId=${lead.leadId}`}
                target="_blank"
                rel="noreferrer"
                className="fb-icon-wrapper"
              >
                <img src={Facebook} alt="no-media" />
              </a>
              <a
                href={`https://www.facebook.com/messages/t/${lead.leadId}?leadId=${lead.leadId}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={Messenger} alt="no-media" />
              </a>
            </div>
          </div>
        </div>
        <span>
          {editAddLink ? (
            <>
              <span className="contion-add-link pipeline-add-link-label">Add Link</span>
              <div className="d-flex align-items-center pipeline-add-link-item">
                <Input
                  onChange={(event) => {
                    setLinkValue(event.target.value);
                  }}
                  className="Add-link-input-wrapper"
                  type="text"
                  value={linkValue}
                  placeholder="mysite"
                />
                <FaTimes
                  className="crose-icon-custom"
                  onClick={() => {
                    setAddLink(false);
                    setLinkValue("");
                  }}
                />
                <FiCheck
                  onClick={() => {
                    if (!linkValue) return;
                    handleUpdateLeadsField({
                      leadId: lead.leadId,
                      label: "links",
                    });
                  }}
                  className="check-custom-icon"
                />
              </div>
            </>
          ) : (
            ""
          )}
        </span>
        <Divider />
        <div className="leads-modal-wrapper">
          <Form layout="vertical" className="edit-lead-modal-form">
            <div className="">
              <Row gutter={24}>
                <Col md={24}>
                  <Form.Item label="Add Lists">
                    <div className="tags-select">
                      <Select
                        allowClear
                        showSearch
                        mode="tags"
                        value={showLeadsLists(lead.lists)}
                        onChange={(event) => {
                          changeLeadsRecord({ label: "lists", event });
                        }}
                        filterOption={(input, option) =>
                          (option?.children ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        className="select-drop-down add-tags-wrapper"
                        // defaultValue="select"
                        placeholder="Lists"
                      >
                        {lists?.map((data, index) => {
                          return (
                            <Option key={index} value={data._id}>
                              {data.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item label="Add Tag">
                    <Select
                      mode="tags"
                      allowClear
                      showSearch
                      value={showLeadsTags(lead.tags)}
                      placeholder="Tags Mode"
                      className="select-drop-down add-tags-wrapper"
                      onChange={(event) => {
                        changeLeadsRecord({ label: "tags", event });
                      }}
                      filterOption={(input, option) =>
                        (option?.children ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                      }
                    >
                       {tags?.map((data, index) => {
                          return (
                            <Option key={index} value={data._id}>
                              {data.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item
                    label="Notes"
                  >
                    <TextArea
                      rows={4}
                      placeholder="Notes"
                      value={lead.notes}
                      onChange={(event) => {
                        changeLeadsRecord({ label: "notes", event });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} className="edit-modal-select-wrapper">
                  <Form.Item label="Add to Pipeline">
                    <Select
                      allowClear={pipelineStageData.pipelineId ? true : false}
                      className="selector-pipeline select-drop-down"
                      onChange={(event) => {
                        setPipelineStageData({
                          ...pipelineStageData,
                          pipelineId: event,
                        });
                        handleGetPipelineStages(event);
                      }}
                      value={pipelineStageData.pipelineId}
                      placeholder="Select Pipeline"
                    >
                      {pipelinesData?.map((data, index) => {
                        return (
                          <Option key={index} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} className="edit-modal-select-wrapper-1">
                  <Form.Item label="Select Stage">
                    <Select
                      allowClear={pipelineStageData.stageId ? true : false}
                      className="selector-pipeline select-drop-down"
                      placeholder="Select Stage"
                      value={pipelineStageData.stageId}
                      onChange={(event) => {
                        setPipelineStageData({
                          ...pipelineStageData,
                          stageId: event,
                        });
                      }}
                    >
                      {pipelineStages?.map((data, index) => {
                        return (
                          <Option key={index} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        {lead?.pipelines && (
          <div className="pipeline-info-wrapper">
            <h1>Pipelines</h1>
            {lead?.pipelines?.map((pipeline) => (
              <div key={pipeline.pipelineId._id} className="pipeline-item">
                <h3>{pipeline.pipelineId.name}</h3>
                <Tag>
                  <span>{pipeline.stageId.name}</span>
                </Tag>
              </div>
            ))}
          </div>
        )}
        <div className="modal-footer d-flex justify-content-end">
          <Button
            onClick={handleCancelEditLead}
            size="default"
            outlined
            type="white"
          >
            Cancel
          </Button>
          <Button
            size="default"
            type="primary"
            className="ml-2 save-btn-modal"
            onClick={handleUpdateLead}
          >
            {leadsLoading ? <Spin size="small" /> : "Save"}
          </Button>
        </div>
      </Modal>

      <Modal
        className="addEvent-modal crm-basic-modal"
        footer={null}
        type="primary"
        centered
        visible={deleteStageLeads}
        width={440}
        closable={false}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <CloseCircleOutlined className="custom-delete-icon-modal" />
          <h2 className="mb-3 text-center color-dark stage-delete-label-wrapper">
            {"Are you sure you want to remove these leads?"}
          </h2>
          <p className="text-center fs-14 color-gray">
            Leads belonging to this stage will be removed and you will have to add them again.
          </p>
        </div>
        <div className="modal-footer d-flex justify-content-center">
          <Button
            size="default"
            outlined
            type="white"
            onClick={() => setDeleteStageLeads(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteStageLeads}
            size="default"
            type="primary"
            className=""
          >
            {loading ? <Spin size="small" /> : "Remove"}
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

PipelineColumn.propTypes = {
  status: propTypes.string,
  children: propTypes.array,
  changeTaskStatus: propTypes.func,
};
export default PipelineColumn;
