/*global chrome*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Tooltip, Spin, Select } from "antd"

import { Link, NavLink } from "react-router-dom"

import { ChartjsAreaChart } from '../../components/charts/chartjs';
import { Cards } from '../../components/cards/frame/cards-frame';
import { customTooltips, chartLinearGradient } from './utilities';

import Facebook from "../../static/img/facebook.svg";
import Messenger from "../../static/img/messenger.svg";
import NoUser from "../../static/img/no-user.png";

import { RevenueWrapper } from "./revenueStyle"

import GraphOne from "../../static/img/graph.svg"
import GraphTwo from "../../static/img/2graph.svg"
import GraphThree from "../../static/img/3graph.svg"
import GraphFour from "../../static/img/4graph.svg"
import GraphFive from "../../static/img/5graph.svg"
import GraphSix from "../../static/img/6graph.svg"
import GraphSeven from "../../static/img/7graph.svg"
import GraphEight from "../../static/img/8graph.svg"
import Play from "../../static/img/play.svg"
import HeadPhone from "../../static/img/headphone.svg"
import LockIcon from "../../static/img/lock-round.svg"
import Notification from "../ant-notification/notification";

import { Button } from "../../components/buttons/buttons";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { MdErrorOutline } from "react-icons/md";
import { BiX } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";

import { GoSync } from "react-icons/go";

import {
  UserAddOutlined,
  MessageOutlined,
  UsergroupAddOutlined,
  SolutionOutlined,
} from "@ant-design/icons";

import {
  ExtensionId,
  parseData
} from "../../utils/constants";

import {
  GetDashboardRecord,
  SetState,
  GetDashboardGraph
} from '../../redux/slices/dashboard-slice';

import { UpdateUserRecord, SetState as SetAuthState } from '../../redux/slices/auth-slice';

import { GetLeadsData } from '../../redux/slices/leads-slice';
import { GetLists , GetTags } from "../../redux/slices/list-slice";

import { TableWrapper } from "../styled";
import { DashboardWrapper } from './style';

const timeLineTypes = {
  'week': 7,
  'month': 30,
  'year': 365
}

const TableColumns = [
  {
    title: "Profile",
    dataIndex: "profile",
    width: 300,
    render: (data) => <div>{data}</div>,
  },
  {
    title: "Profile Link",
    dataIndex: "profile_link",
    width: 100,
    render: (data) => <div>{data}</div>,
  },
  {
    title: "Tag",
    dataIndex: "tag",
    width: 209,
  },
  {
    title: "List",
    dataIndex: "list_select",
    width: 201,
  },
  {
    title: "Notes",
    dataIndex: "notes",
    width: 200,
  },
  {
    title: "Relationship",
    dataIndex: "relationship",
    width: 100,
  }
];

const getCardValue = ({
  id,
  value,
  counterValue,
  label,
  rating,
  graph
}) => {
  return {
    id,
    value,
    counterValue,
    label,
    rating,
    date: "Since last week",
    image: <img src={graph} />,
  }
}

const Dashboard = () => {

  const cookies = new Cookies();
  const dispatch = useDispatch();
  const history = useHistory();
  const dashboardData = useSelector((state) => state.dashboard);
  const { leadsData } = useSelector((state) => state.leads);
  const { lists , tags } = useSelector((state) => state.list);
  const { token, friendsSynced = false } = useSelector((state) => state.auth);

  const [chartTimeline, setChartTimeline] = useState('week');
  const [loading, setLoading] = useState(false);
  const [graphType, setGraphType] = useState('leads');
  const [leads, setLeads] = useState([]);
  const [cardsValue, setCardsValue] = useState([]);
  const [showTags, setShowTags] = useState(null);
  const [showList, setShowList] = useState(false);
  const [extensionInstalled, setExtensionInstalled] = useState(true);
  const [showExtensionLabel, setShowExtensionLabel] = useState(true);
  const [extensionLoading, setExtensionLoading] = useState(false);
  const [importFriendsLoading, setImportFriendsLoading] = useState(false);
  const [sort, setSort] = useState([["topMessengerLeads", "ascending"]]);
  const [sortAscending, setSortAscending] = useState([["createdAt", "ascending"]]);
  const [chartLeads, setChartLeads] = useState([]);
  const [chartRows, setChartRows] = useState([]);
  const { information, success, err, chartLoading, graphData } = dashboardData;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 10
  });

  const [filter, setFilter] = useState({
    keyword: "",
    postId: null,
    groupId: null,
    tags: [],
    lists: [],
    noFriends: false,
    friendsOnly: false,
    requestSent: false,
    messenger: true
  });

  const checkExtensionInstalled = () => {
    if (!chrome.runtime) return setExtensionInstalled(false);

    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "IS_EXTENSION_INSTALLED",
      },
      async (response) => {
        if (response && response.success) setExtensionInstalled(true)
        else setExtensionInstalled(false)
      }
    );
  };

  useEffect(() => {
    const loggedInCookie = cookies.get('loggedIn');
    const tokenCookie = cookies.get('token');
    if (!loggedInCookie) {
      const current = new Date();
      const nextYear = new Date();
      nextYear.setFullYear(current.getFullYear() + 1);
      cookies.set('loggedIn', true, { path: '/', expires: nextYear });
    }
    if (!tokenCookie) {
      const current = new Date();
      const nextYear = new Date();
      nextYear.setFullYear(current.getFullYear() + 1);
      cookies.set('token', token, { path: '/', expires: nextYear });
    }
    dispatch(GetDashboardRecord());
    dispatch(GetDashboardGraph({ graphType: 'leads' }));
    checkExtensionInstalled();
    dispatch(GetLeadsData({ filter, sort, skip: 0, limit: 5 }));
    dispatch(GetLists({ filter: { keyword: "" }, sort: sortAscending, skip: 0 }));
    dispatch(GetTags({ filter: { keyword: "" }, sort: sortAscending, skip: 0 }));
  }, [])

  useEffect(() => {
    const { success, err } = dashboardData;
    if (success) {
      Notification({
        type: "success",
        message: typeof success === "object" ? 'Success' : success,
      });
      dispatch(SetState({ field: "success", value: false }));
    }
    if (information) {
      Notification({
        type: "success",
        message: typeof information === "object" ? 'Success' : information,
      });
      dispatch(SetState({ field: "information", value: false }));
    }
    if (err) {
      Notification({
        type: "error",
        message: typeof err === "object" ? 'Error! Please Try Again' : err,
      });
      dispatch(SetState({ field: "err", value: false }));
    }
  }, [success, err, information])

  useEffect(() => {
    setLeads(JSON.parse(JSON.stringify(leadsData)));
  }, [leadsData]);

  const imageErrors = (image) => {
    if (document.querySelector(`img[src="${image}"]`))
      document.querySelector(`img[src="${image}"]`).src = NoUser;
  };

  const getRatio = (value = 0) => {
    return value && !isNaN(value) ? value?.toFixed(2) : 0.00
  }

  const handleTableChange = (newPagination, filters, sorter) => {
    const { current: newPage, pageSize: limit, total } = newPagination;
    setPagination({ current: newPage, pageSize: limit, total });
    dispatch(GetLeadsData({ filter, sort, skip: (newPage - 1) * limit, limit }));
  };

  const handleString = (str) => {
    if (str) {
      if (str.length < 20) return str;
      const maxLength = 20;
      const title = str?.substr(0, maxLength);
      return `${title}.....`;
    } else return "";
  };

  const showTagsData = ({ leadId, tagsData }) => {
    return (
      <div className="tags-stucture d-flex">
        <>
          {[0, 1].map((index) => {
            return tagsData[index] ? (
              <div
                key={`key-${index}`}
                className="d-flex tags-wrapper-stucture"
              >
                <span className="tags-wrapper tags-content status Success">
                {tags.find((tag) => tag._id === tagsData[index])?.name || tagsData[index] }
                </span>
              </div>
            ) : (
              ""
            );
          })}
          {tagsData.length > 2 && (
            <div className="d-flex tags-btn-wrapper-stucture">

              <span
                className="btn btn-success"
                onMouseEnter={() => setShowTags(leadId)}
                onMouseLeave={() => setShowTags(null)}
              >
                {tagsData.length <= 10 ? tagsData.length : "10+"}
              </span>
              {showTags === leadId && tags.length > 2 && (
                <div className="tags-box">
                  <div className="d-flex flex-wrap overlay-tags">
                    {tagsData?.map((tag, index) => {
                      return (
                        <div key={index} className="d-flex tags-wrapper-stucture">
                          <span className="tags-wrapper tags-content status Success">
                          {tags.find((tag) => tag._id === tagsData[index])?.name || tagsData[index] }
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      </div>
    );
  };

  const showListData = ({ leadId, listsData }) => {
    return (
      <div className="tags-stucture d-flex">
        <>
          {[0, 1].map((index) => {
            return listsData[index] ? (
              <div
                key={`key-${index}`}
                className="d-flex tags-wrapper-stucture"
              >
                <span className="tags-wrapper tags-content status Success">
                  {lists?.find((list) => list?._id === listsData[index])?.name}
                </span>
              </div>
            ) : (
              ""
            );
          })}
          {listsData.length > 2 && <div className="d-flex tags-btn-wrapper-stucture">
            <span
              className="btn btn-success"
              onMouseEnter={() => setShowList(leadId)}
              onMouseLeave={() => setShowList(null)}
            >
              {listsData?.length <= 10 ? listsData?.length : "10+"}
            </span>
            {showList === leadId && listsData?.length > 2 && (
              <div className="tags-box">
                <div className="d-flex flex-wrap overlay-tags">
                  {listsData?.slice(2, listsData.length)?.map((list, index) => {
                    return (
                      <div key={index} className="d-flex tags-wrapper-stucture">
                        <span className="tags-wrapper tags-content status Success">
                          {lists?.find((data) => data?._id === list)?.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>}
        </>
      </div>
    );
  };

  const setRelationshipStatus = (status) => {
    const { friends, groupMember, postMember, messenger } = status;

    return (
      <div className="d-flex profile-url gap-3">
        {friends && (
          <Tooltip placement="top" title={"Facebook Friends"}>
            <UserAddOutlined />
          </Tooltip>
        )}
        {groupMember && (
          <Tooltip placement="top" title={"Group Member"}>
            <UsergroupAddOutlined />
          </Tooltip>
        )}
        {postMember && (
          <Tooltip placement="top" title={"Post Member"}>
            <SolutionOutlined />
          </Tooltip>
        )}
        {messenger && (
          <Tooltip placement="top" title={"Messenger"}>
            <MessageOutlined />
          </Tooltip>
        )}
      </div>
    );
  };

  const data = [];
  leads?.forEach((lead, index) => {
    data?.push({
      key: index,
      profile: (
        <div className="d-flex user-profile gap-3">
          <img
            className="user-img-dashboard"
            src={lead?.image}
            height={5}
            width={5}
            onError={() => {
              imageErrors(lead?.image);
            }}
            alt={""}
          />
          <div className="heading-user-content-wrapper">
            <span>{lead?.name}</span>
            <div className="d-flex lead-info-user">
              <Tooltip placement="top" title={lead?.contact}>
                <label className='leads-user-number'>{lead?.contact ? `${lead?.contact}` : ""}</label></Tooltip>
              <Tooltip placement="top" title={lead?.email}>
                <label>{handleString(lead?.email)}</label></Tooltip>
            </div>
          </div>
        </div>
      ),
      profile_link: (
        <div className="user-profile-link">
          <a href={`https://www.facebook.com/${lead?.leadId}?leadId=${lead.leadId}`} target="_blank">
            <img width="20" height="20" src={Facebook} alt="no-link" />
          </a>
          <a className="messanger-icon-wrap"
            href={`https://www.facebook.com/messages/t/${lead?.leadId}?leadId=${lead.leadId}`}
            target="_blank"
          >
            <img
              className="messanger-icon"
              width="20"
              height="20"
              src={Messenger}
              alt=""
            />
          </a>
        </div>
      ),
      tag: lead.tags?.length
        ? showTagsData({ leadId: lead?.leadId, tagsData: lead?.tags })
        : "",
      list_select: lead?.lists?.length
        ? showListData({ leadId: lead?.leadId, listsData: lead?.lists })
        : "",
      notes: (
        <div className="d-flex align-items-center gap-3 note-tooltip">
          <span>
            <Tooltip placement="top" title={lead?.notes}>
              {handleString(lead?.notes)}
            </Tooltip>
          </span>
        </div>
      ),
      relationship: (
        <div className="d-flex align-items-center gap-3">
          {setRelationshipStatus(lead?.relationship)}
        </div>
      )
    });
  });
  const tableProps = {
    loading,
  };

  useEffect(() => {
    const {
      leadsSynced = {},
      leadsInPipelines = {},
      valueOfDeals = {},
      leadsAddedInPipelines = {},
      columns = [],
    } = dashboardData || {};

    const cards = [];
    for (let i = 0; i < 4; i += 1) {
      switch (i) {
        case 0:
          const {
            value: totalSynced = 0,
            rating: syncedRatio = 0,
            blocked: blockedLeadsCount = 0,
          } = leadsSynced || {};
          cards?.push(getCardValue({
            id: i,
            label: "Leads Added",
            value: totalSynced,
            rating: getRatio(syncedRatio),
            counterValue: blockedLeadsCount,
            graph: GraphOne
          }));
          continue;
        case 1:
          const {
            value: totalLeadsMoved = 0,
            rating: leadsRating = 0
          } = leadsAddedInPipelines || {};
          cards.push(getCardValue({
            id: i,
            label: "Leads Added To Pipeline",
            value: totalLeadsMoved,
            rating: getRatio(leadsRating),
            graph: GraphTwo
          }));
          continue;
        case 2:
          const { value: totalLeads = 0, rating: leadsInPipelinesRatio = 0 } = leadsInPipelines || {};
          cards.push(getCardValue({
            id: i,
            label: "Leads Currently in Pipelines",
            value: totalLeads,
            rating: getRatio(leadsInPipelinesRatio),
            graph: GraphThree
          }));
          continue;
        case 3:
          const { value: dealsValue = 0, rating: dealsRating = 0 } = valueOfDeals || {};
          cards.push(getCardValue({
            id: i,
            label: "Value Of Deals",
            value: dealsValue,
            rating: getRatio(dealsRating),
            graph: GraphFive
          }));
          continue;
      }
    }

    const customCards = columns?.map((column, index) => {
      const {
        value = 0,
        rating: customColumnRating = 0.00,
        name: label
      } = column || {};
      const items = [GraphSix, GraphSeven, GraphEight];

      return getCardValue({
        id: 5 + index,
        label,
        value,
        rating: getRatio(customColumnRating),
        graph: items[Math.floor(Math.random() * items.length)]
      })
    }) || [];
    setCardsValue([...cards, ...customCards])

  }, [dashboardData])

  const getAllLeadsTimelineData = ({ timeline = 'week', graphData }) => {
    const range = timeLineTypes[timeline];
    const chartDate = new Date(graphData[0]?.syncedAt);
    const lastDate = new Date(graphData[graphData.length - 1]?.syncedAt);
    const chartTimelines = [];
    const chartLeads = [];

    while (chartDate.getTime() < lastDate.getTime()) {
      chartTimelines.push(moment(chartDate).format("MMM Do"));
      const totalLeads = graphData.findIndex((data) => new Date(data.syncedAt)?.getTime() > new Date(chartDate).getTime())
      chartLeads.push(totalLeads)
      chartDate.setDate(chartDate.getDate() + range);
    }
    chartLeads.push(graphData.length - 1);

    chartTimelines.push(moment(lastDate).format("MMM Do"));
    return { chartTimelines, chartLeads };
  }

  const getActiveLeadsTimelineData = ({ timeline = 'week', graphData }) => {
    const range = timeLineTypes[timeline];
    const chartDate = new Date(graphData[0]?.placedAt);
    const lastDate = new Date(graphData[graphData.length - 1]?.placedAt);
    const chartLeads = [];
    const chartTimelines = [];

    while (chartDate.getTime() < lastDate.getTime()) {
      chartTimelines.push(moment(chartDate).format("MMM Do"));
      const totalLeads = graphData.findIndex((data) => new Date(data.placedAt)?.getTime() > new Date(chartDate).getTime())
      chartLeads.push(totalLeads)
      chartDate.setDate(chartDate.getDate() + range);
    }
    chartLeads.push(graphData.length);
    chartTimelines.push(moment(lastDate).format("MMM Do"));
    return { chartTimelines, chartLeads };;
  }

  const setGraphData = ({ timeline }) => {
    const graphDataArray = parseData(JSON.stringify(graphData));

    if (graphType === 'leads') {
      const { chartLeads = [], chartTimelines = [] } = getAllLeadsTimelineData({ timeline, graphData: graphDataArray });
      setChartRows(chartTimelines);
      setChartLeads(chartLeads);
    } else {
      const { chartLeads = [], chartTimelines = [] } = getActiveLeadsTimelineData({ timeline, graphData: graphDataArray });
      setChartRows(chartTimelines);
      setChartLeads(chartLeads);
    }
  }

  useEffect(() => {
    setGraphData({ timeline: chartTimeline });
  }, [graphData])

  const chartDatasets = chartLeads !== null && [
    {
      data: chartLeads,
      borderColor: '#5F63F2',
      borderWidth: 4,
      fill: true,
      backgroundColor: () =>
        chartLinearGradient(document.getElementById('performance'), 300, {
          start: '#5F63F230',
          end: '#ffffff05',
        }),
      label: 'Total Leads',
      pointStyle: 'circle',
      pointRadius: '0',
      hoverRadius: '9',
      pointBorderColor: '#fff',
      pointBackgroundColor: '#5F63F2',
      hoverBorderWidth: 5,
      amount: graphData.length,
      amountClass: 'current-amount',
    },
  ];

  const options = [
    {
      value: 'leads',
      label: 'Leads',
    },
    {
      value: 'activeLeads',
      label: 'Active Leads',
    }
  ];

  const handleChangeGraphTimeline = value => {
    setChartTimeline(value);
    setGraphData({ timeline: value });
  };

  const handleChangeGraphType = (value) => {
    setGraphType(value);
    dispatch(GetDashboardGraph({ graphType: value }));
  }

  const syncFriendsGraphql = async ({ type }) => {
    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "CHECK_LOGGED_IN",
      },
      async ({ loggedIn }) => {
        if (loggedIn) {
          chrome.runtime?.sendMessage(
            ExtensionId,
            { messageType: "FETCH-GRAPHQL-FRIENDS", token, syncType: type },
            async (data) => {
              setExtensionLoading(false);
              setImportFriendsLoading(false);
              if (data?.isUserValid === false) return dispatch(SetState({ field: 'err', value: 'This Facebook Account Is Not Attached' }));
              dispatch(
                SetAuthState({
                  field: "friendsSynced",
                  value: true,
                })
              );
              await dispatch(UpdateUserRecord({ friendsSynced: true }));
              setTimeout(() => { history.push('/background-jobs') }, 5000)
            }
          );
        } else {
          setExtensionLoading(false);
          setImportFriendsLoading(false);
          return dispatch(SetState({ field: 'err', value: 'Please Login To Facebook' }));
        }
      });





  };

  const handleTopLeads = async () => {
    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "CHECK_LOGGED_IN",
      },
      async ({ loggedIn }) => {
        if (loggedIn) {
          await chrome.runtime?.sendMessage(
            ExtensionId,
            { messageType: "FETCH-TOP-LEADS", token },
            async (data) => {
              if (data?.isUserValid === false) return dispatch(SetState({ field: 'err', value: 'This Facebook Account Is Not Attached' }))
              dispatch(
                SetState({
                  field: "success",
                  value: "Syncing Recent Activity In The Opened Window",
                })
              );
              await dispatch(
                GetLeadsData({
                  filter,
                  sort,
                  skip: 0,
                  limit: pagination.pageSize,
                })
              );
            }
          );
        }
        else {
          //  setImportFriendsLoading(false);
          return dispatch(SetState({ field: 'err', value: 'Please Login To Facebook' }));
        }
      });
  }

  return (
    <DashboardWrapper>
      <div id="sync-friends-div" className='header-wrapper d-flex justify-content-between align-items-center'>
        <h1 className='m-0'>Dashboard</h1>
        <div className='dashbaord-header-button-wrapper'>
          <Button
            outlined
            disabled={extensionLoading || !extensionInstalled}
            className="sync-facebook-friend"
            onClick={() => {
              setExtensionLoading(true);
              syncFriendsGraphql({ type: 'syncFriends' })
            }}
          >{<div className='d-flex'><GoSync />{extensionLoading ? 'Syncing Started...' : 'Sync Facebook Friends'}</div>}
          </Button>
          <Button
            outlined
            onClick={handleTopLeads}
            disabled={!extensionInstalled}
            className="sync-facebook-friend"
          >{<div className='d-flex'><GoSync /> Sync Recent Messages </div>}</Button>
        </div>
      </div>
      <div className='dashboard-overflow-wrappper'>
        {!extensionInstalled && showExtensionLabel && <div className='error-notification-wrapper d-flex justify-content-between align-items-center'>
          <div className='error-inner'><MdErrorOutline /><span>The Chat Pro Chrome Extension is required for Chat Pro to work. Please open the extension or <a
            className='ancer-tags-wrapper'
            target='_blank'
            href='https://chrome.google.com/webstore/detail/chat-pro/ecgidnnlichmoalgoabboefegghdipmc'>
            click here </a>
            to install it.
          </span>
          </div>
          <BiX onClick={() => { setShowExtensionLabel(false) }} className='crose-icon-wraper' />
        </div>}
        <div className='graph-wrapper'>
          <div className="card-grid-wrapper">
            <Row className='m-0' gutter={[30, 25]}>
              {cardsValue.map(({ value, counterValue, label, rating, date, image, id }) => {
                return (
                  <Col key={id} span={6}>
                    <div className='column-wrapper'>
                      <div className="cards-wrapper d-flex justify-content-between">
                        <div className='content-area'>
                          <p className='m-0 message'>{label}</p>
                          <div className='d-flex justify-content-between'>
                            <div>
                              <h1 className='m-0'>{value}</h1>
                              <div className='d-flex rating-date-wrapper'>
                                <p className='m-0 rating-wrapper'><>
                                  {rating >= 100 ? <BsArrowUpShort />
                                    : <BsArrowDownShort />}<span>{rating}</span></></p>
                                <p className='m-0 date-wrapper'>{date}</p>
                              </div>
                              {
                                counterValue !== undefined && (
                                  <Link to="/blocked-leads" className="" style={{fontSize: "12px", color: "#323232"}}>
                                    (Blocked users {counterValue})
                                  </Link>
                                )
                              }
                            </div>
                            <div className='icons-wrapper d-flex justify-content-end'>
                              {image}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
          <div className='bg-graph-wrapper'>
            <Row gutter={30}>
              <Col md={16}>
                <RevenueWrapper>
                  {chartLeads && chartRows && (
                    <Cards
                      isbutton={
                        <div className="card-nav">
                          <Select
                            className="dashboard-chart-select"
                            placeholder="leads"
                            onChange={handleChangeGraphType}
                            defaultValue={"Leads"}
                            options={options}
                          />
                          <ul>
                            <li className={chartTimeline === 'week' ? 'active' : 'deactivate'}>
                              <Link onClick={() => handleChangeGraphTimeline('week')} to="#">
                                Week
                              </Link>
                            </li>
                            <li className={chartTimeline === 'month' ? 'active' : 'deactivate'}>
                              <Link onClick={() => handleChangeGraphTimeline('month')} to="#">
                                Month
                              </Link>
                            </li>
                            <li className={chartTimeline === 'year' ? 'active' : 'deactivate'}>
                              <Link onClick={() => handleChangeGraphTimeline('year')} to="#">
                                Year
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                      size="large"
                    >
                      {chartLoading ? (
                        <div className="sd-spin">
                          <Spin size="large" />
                        </div>
                      ) : (
                        <div className="performance-lineChart">
                          <ul>
                            {chartDatasets &&
                              chartDatasets?.map((item, key) => {
                                return (
                                  <li key={key + 1} className="custom-label">
                                    <strong className={item.amountClass}>{item.amount}</strong>
                                    <div>
                                      <span
                                        style={{
                                          backgroundColor: item.borderColor,
                                        }}
                                      />
                                      {item.label}
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>

                          <ChartjsAreaChart
                            id="performance"
                            labels={chartRows}
                            datasets={chartDatasets}
                            options={{
                              maintainAspectRatio: true,
                              elements: {
                                z: 9999,
                              },
                              legend: {
                                display: false,
                                position: 'bottom',
                                align: 'start',
                                labels: {
                                  boxWidth: 6,
                                  display: false,
                                  usePointStyle: true,
                                },
                              },
                              hover: {
                                mode: 'index',
                                intersect: false,
                              },
                              tooltips: {
                                mode: 'label',
                                intersect: false,
                                backgroundColor: '#ffffff',
                                position: 'average',
                                enabled: false,
                                custom: customTooltips,
                                callbacks: {
                                  title() {
                                    return `Total Leads`;
                                  },
                                  label(t, d) {
                                    const { yLabel, datasetIndex } = t;
                                    return `<span class="chart-data">${yLabel}</span> <span class="data-label">${d.datasets[datasetIndex].label}</span>`;
                                  },
                                },
                              },
                              scales: {
                                yAxes: [
                                  {
                                    gridLines: {
                                      color: '#e5e9f2',
                                      borderDash: [3, 3],
                                      borderPadding: 9,
                                      zeroLineColor: '#e5e9f2',
                                      suggestedMin: 0,
                                      suggestedMax: 1,
                                      zeroLineBorderDash: [3, 3],
                                    },
                                    ticks: {
                                      beginAtZero: true,
                                      fontSize: 14,
                                      fontColor: '#182b49',
                                      padding: 3,
                                      stepSize: 1200,
                                      callback(label) {
                                        return `${label}`;
                                      },
                                    },
                                  },
                                ],
                                xAxes: [
                                  {
                                    gridLines: {
                                      color: '#e5e9f2',
                                      borderDash: [3, 3],
                                      tickMarkLength: 10,
                                      borderPadding: 9,
                                      zeroLineColor: '#e5e9f2',
                                      suggestedMin: 0,
                                      suggestedMax: 1,
                                      zeroLineBorderDash: [3, 3],
                                    },
                                    ticks: {
                                      beginAtZero: true,
                                      autoSkipPadding: 100,
                                      fontSize: 14,
                                      fontColor: '#182b49',
                                      padding: 30,
                                      stepSize: 9000,
                                      afterFit: function (scale) {
                                        scale.width = 1200 //<-- set value as you wish 
                                      },
                                      callback(label) {
                                        return `${label}`;
                                      },
                                    },
                                  },
                                ],
                              }
                            }}
                            height={window.innerWidth <= 575 ? 200 : 82}
                          />
                        </div>
                      )}
                    </Cards>
                  )}
                </RevenueWrapper>
              </Col>
              <Col md={8}>
                <div className='setup-steps-card-wrapper'>
                  <div className='setup-steps-card'>
                    <h3 className='m-0'>Setup Steps</h3>
                    <div className='divider-card' />
                    <div id="import-friends-div" className='icons-text-wrapper d-flex flex-column align-items-center'>
                      <AiOutlineCheckCircle />
                      <p className='m-0'>Sign Up for ChatPro</p>
                      <p className='m-0 vertical-divider' />
                      {!extensionInstalled ? <img src={LockIcon} className="lock-svg-wrapper" />
                        : <AiOutlineCheckCircle />}
                      {!extensionInstalled ? <Button className="install-extention-btn"><a
                        target='_blank'
                        href="https://chrome.google.com/webstore/detail/chat-pro/ecgidnnlichmoalgoabboefegghdipmc"
                      >Install the ChatPro Chrome Extension</a></Button> :
                        <p className='m-0'>Extension Installed</p>}
                      <p className='m-0 vertical-divider' />
                      {!friendsSynced ? <img src={LockIcon} className="lock-svg-wrapper" /> : <AiOutlineCheckCircle />}
                      {!friendsSynced ? <Button
                        disabled={importFriendsLoading || !extensionInstalled}
                        className="import-btn"
                        onClick={() => {
                          setImportFriendsLoading(true)
                          syncFriendsGraphql({ type: 'importFriends' })
                        }}
                      >{importFriendsLoading ? 'Syncing Started...' : 'Import Facebook Friends'}</Button>
                        : <p className='m-0'>Facebook Friends Imported</p>
                      }
                    </div>
                  </div>
                  <div className='fotter-section '>
                    <p className='m-0 text-center'>Having Issues?</p>
                    <div className=' fotter-inner d-flex justify-content-between align-items-center'>
                      <p className='play-wrapper m-0'><img src={Play} /> <a href='https://www.loom.com/share/e4bae0b76e92474682e91fd51628a13e' target="_blank" rel="noopener noreferrer">See Video</a></p>
                      <p className='m-0 divider-wrapper'>OR</p>
                      <p className='m-0 call-wrapper'><img src={HeadPhone} /><a href='https://api.leadconnectorhq.com/widget/bookings/chatprosupport' target="_blank" rel="noopener noreferrer">Book a Call</a></p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <TableWrapper className='dashboard-loader-wrapper'>
          <div className='dashboard-table-wrap'>
            <div className='heading-wrapper-dashbaord'>
              <h1>Recent Activity</h1>
            </div>
            <Table
              sticky
              size="small"
              dataSource={data}
              pagination={pagination}
              columns={TableColumns}
              scroll={{ x: 1200 }}
              onChange={handleTableChange}
              className="dashboard-table-wrapper"
              {...tableProps}
            >
            </Table>
          </div>
        </TableWrapper>
      </div>
    </DashboardWrapper>
  );
}

export default Dashboard;
