import React from "react";
import { orderBy } from 'lodash';
import { MdPayment } from "react-icons/md";
import FeatherIcon from "feather-icons-react";
import { BsCurrencyDollar } from "react-icons/bs";

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const parseData = (data) => {
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
}

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const isValidURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

// export const ExtensionId = process.env.NODE_ENV === 'development' ? 'adiljojjkdpmijabgmaacmmdaojehjjn' : 'ecgidnnlichmoalgoabboefegghdipmc';    // Production
export const ExtensionId = 'ecgidnnlichmoalgoabboefegghdipmc';

export const relationshipOptions = [
  {
    key: "friendsOnly",
    value: <><span className="option-overflow">Friends Only</span></>,
  },
  {
    key: "requestSent",
    value: <><span className="option-overflow">Pending Requests</span></>,
  },
  {
    key: "messenger",
    value: <><span className="option-overflow">Messenger</span></>,
  },
  {
    key: "noFriends",
    value: <><span className="option-overflow">Others</span></>,
  },
];

export const highLevelPlanTags = {
  'Standard Plan': 'chatpro standard',
  'Beta Plan': 'chatpro beta',
  'Free Plan': 'chatpro free'
}

export const orderArray = ({ array, label = 'placedAt', type = 'desc' }) => {
  return orderBy(array, [label], [type])
}

export const sortArray = ({ array, label }) => {
  array.sort((a, b) => {
    const firstStage = a[label];
    const secondStage = b[label];

    if (firstStage < secondStage) {
      return -1;
    }
    if (firstStage > secondStage) {
      return 1;
    }
    return 0;
  });
};

export const chars = "0123456789ABCDEFGHIKLMNOPRSTUVWXYZ0123456789";

export const getRandomString = (length) => {
  let charsArray = [];
  for (let i = 0; i < length; i++)
    charsArray.push(chars[Math.floor(Math.random() * chars.length)]);
  return charsArray.join("");
};

export const userStatus = [
  {
    name: 'Active',
    value: 'Active'
  },
  {
    name: 'Subscribed',
    value: 'Subscribed'
  },
  {
    name: 'Verified',
    value: 'Verified'
  },
  {
    name: 'Pending',
    value: 'Pending'
  },
]


export const countries = [
  { name: 'United States', label: 'United States' },
  { name: 'Canada', label: 'Canada' },
  { name: 'United Kingdom', label: 'United Kingdom' },
];

export const states = [
  { name: 'California', label: 'California' },
  { name: 'New York', label: 'New York' },
  { name: 'Ontario', label: 'Ontario' },
];

export const cities = [
  { name: 'Los Angeles', label: 'Los Angeles' },
  { name: 'San Francisco', label: 'San Francisco' },
  { name: 'New York City', label: 'New York City' },
];


export const backgroundStatusColor = {
  'Active': '#4eaf24',
  'Subscribed': '#9fc5e8',
  'Verified': '#ffbe6d',
  'Pending': '#f25f54'
};

export const Items = [
  {
    label: (
      <>
        <span className="tab-item">
          <span className="icon-list tab-icon" />
          Invoices
        </span>
      </>
    ),
    key: "invoice",
    children: "Content 1",
  },
  {
    label: (
      <>
        <span className="tab-item">
          <MdPayment className="tab-icon" />
          Payment Method
        </span>
      </>
    ),
    key: "payment",
    children: "Content 2",
  },
  {
    label: (
      <>
        <span className="tab-item">
          {" "}
          <FeatherIcon className="tab-icon" size={16} icon="radio" /> Social
          Media Platform
        </span>
      </>
    ),
    key: "media",
    children: "Content 2",
  },
  {
    label: (
      <>
        <span className="tab-item">
          <BsCurrencyDollar className="tab-icon" />
          Subscription Plans
        </span>
      </>
    ),
    key: "plans",
    children: "Content 2",
  },
];
