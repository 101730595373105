import styled from "styled-components";

const TemplateStyleWrapper = styled.div`
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
    .search_wrapper {
      position: relative;
      div {
        width: 252px;
        svg {
          top: 11px;
        }
      }
    }
    h1 {
      margin: 0;
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .folder-loading{
    .ant-spin.ant-spin-lg.ant-spin-spinning {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}
  }
  .active-status-button{
    border:1px solid #5F63F2;
  }
  .content-templates-overaly {
    margin-top: 24px;
    .tabs-wrapper {
      height: auto;
      width: 100%;
      background: #fff;
      border-radius: 4px;
      padding-bottom: 24px;
      .tabs-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eceef5;
        h2 {
          margin-bottom: 0;
          color: #272b41;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-left: 24px;
          padding-bottom: 16px;
          padding-top: 16px;
        }
        svg {
          font-size: 20px;
          color: #5f63f2;
          cursor: pointer;
          margin-right: 24px;
        }
      }
    }
    .buttons-wrapper {
      padding: 24px;
      display: flex;
      flex-direction: column;
      grid-row-gap: 16px;
      height:542px;
      overflow:auto ;
    }
  }
  .template-content-wrapper {
    background: #fff;
    border-radius: 4px;
    padding-bottom: 24px;
    .template-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eceef5;
      padding-bottom: 16px;

      h3 {
        color: #272b41;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        padding-left: 24px;
        padding-top: 16px;
        margin-bottom: 0px;
      }
      button {
        display: flex;
        align-items: center;
        color: #5f63f2;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        grid-gap: 4px;
        padding: 11px 16px;
        margin-top: 16px;
        margin-right: 24px;
      }
    }
    .data-Template-wrapper {
      padding: 24px;
      height: 523px;
      overflow:auto ;
    }
  }
`;

const CardStyleWrapper = styled.div`
  border-radius: 12px;
  border: 1px solid #eceef5;
  background: #fff;
  padding: 16px;
  .card-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .card-header p {
    color: #17181a;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    margin-bottom: 16px;
  }

  .action-icons {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .card-header p {
      color: #17181a;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      margin-bottom: 16px;
    }
  }
  .action-icons {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    font-size: 16px;
    svg {
      font-size: 16px;
      color: #5f63f2;
      cursor: pointer;
    }
  }
  .card-discruption {
    color: #797c80;
    margin-bottom: 0px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    a {
      color: #5f63f2;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      text-decoration-line: underline;
    }
  }
`;
const FolderButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eceef5;
  background: #fff;
  p {
    color: #17181a;
    margin: 0px;
    user-select: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding: 16px;
    width: 90%;
    cursor: pointer;
  }
  svg {
    color: #5f63f2;
    cursor: pointer;
    font-size: 16px;
    width: 10%;
  }
`;
export { TemplateStyleWrapper, CardStyleWrapper, FolderButtonWrapper };
