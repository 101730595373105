import styled from "styled-components";
import Styled from "styled-components";

const KanvanBoardWrap = Styled.div`
.ant-card-body{
  padding: 23px 24px 24px !important;
  background: white;
  border-radius: 10px;
  margin-bottom:14px;
  height: calc(100vh - 188px);
}
.pipeline-info-wrapper {
  margin-top: 20px;
}
.pipeline-info-wrapper h1 {
   font-size :24px ;
   font-weight:700px
}

.pipeline-item {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px !important;

}

.pipeline-item h3 {
  margin-bottom: 5px;
  font-weight:500px
}

.stage-list {
  list-style-type: none;
  padding: 0;
}

.stage-item {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

/* Add more styles if needed */
.stage-item span {
  font-weight: bold;
  color: #333;
}

.stage-item .actions {
  display: flex;
  gap: 10px;
}

.main-card-header{
  display: flex;
  justify-content:space-between;
}
.loader-center-wrapper {
    height: 100vh;
    .ant-spin-spinning {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    top: 39%;
}
}
.heading-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #4B5664;
    margin-bottom:19px;
}
.site-calendar-demo-card {
  z-index: 100;
}
.heading-num {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
}
.date-text-picker {
    padding-left: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    padding-top: 13px;
}
.ant-picker-header-view button {
    color: inherit;
    font-weight: 600;
    color: #000 !important;
}
input.heading-input {
  width: 175px;
  padding: 7px;
  text-overflow: ellipsis;
  border: none;
  position:absolute;
  z-index:999;
  top:7px;
}
  h1{
    margin-bottom: 8px;
    margin-left: -1px;
    position: relative;
    top: 1px;
    left: 1px;
  }
  .d-flex.justify-content-between{
    padding-left: 13px;
span {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}
  }
  .notes-text {
    display: block;
    padding-top: 2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    flex-wrap: wrap;
    width: 100%;
    overflow-wrap: anywhere;
    color:#6F748C;
    &:before{
    color: #000;
    margin-right: 3px;
    }
}
.hr-line {
    width: 100%;
    height: 1px;
    background: #F4F5F7;
    margin-top: 12px;
}
.card-content-wrappper {
    margin-left: 8px;
    padding-right: 4px;
    span {
    text-align: left;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    font-weight:500;
    margin-left: -1px;
}
.card-note-wrapper{
  color:#6F748C ;
  padding-left:3px;
}
}
.messenger-icon {
  margin-left: 8px;
    margin-top: 0px;
    width: 100%;
    max-width: 17px;
    position: relative;
    top: 7px;
    left:-4px;
}
img.facebook-icon {
  width: 16px;
    margin-left: 9px;
    position: relative;
    top: 7px;
}
.ant-picker-input {
    display: inline-flex;
    align-items: center;
    width: 18px;
    height: 13px;
    position: absolute;
    left: -8px;
    top: 4px;
    padding-left: 5px;
}
.ant-picker {
    min-width: 0px !important;
}
.d-flex.justify-content-between span {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: -1px;
    margin-top: 1px;
    color: #5f63f3;
    margin-right: 0px;
}
span.ant-picker-clear {
    display: none;
}
.ant-picker-header{
  color:#000 !important;
}
.ant-picker-header-view button {
    font-weight: 600;
    color: #000 !important;
    font-size: 16px;
    line-height: 16px;
}
.ant-picker-header-view button {
    font-weight: 600;
    color: #000 !important;
    font-size: 16px;
    line-height: 16px;
}
.horizental-card-icon {
  color: #5f63f3;
  width: 20px;
  height: 20px;
  position: relative;
  right: 7px;
  top:-1px;
}
.ant-picker-panel-container {
    position: relative;
    left: 4px;
    top: -3px;
    border-radius: 8px;
}
  .sDash_kanban-board-list{
    display: flex;
    flex-wrap: nowrap;
    background: #ffffff;
    .sDash_kanban-board-item{
      flex: 0 0 240px;
      padding:5px
      height: calc(100vh - 266px);
      over-flow:auto !important;
      margin-right:19px;
      &:first-of-type{
        margin-left:-5px;
      }
  
      @media only screen and (max-width: 1599px){
        flex: 0 0 240px;
      }
      @media only screen and (max-width: 1399px){
        flex: 0 0 249px !important;
        margin-top: -3px;
        margin-right: 30px;
        margin-left: -3px;
      }
      @media only screen and (max-width: 767px){
        flex: 0 0 50%;
      }
      @media only screen and (max-width: 575px){
        flex: 0 0 100%;
      }
      .ant-anchor-ink {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        display: none;
    }
      .sDash_kanban-board-item-scrolable{
        padding-bottom: 80px;
        border-radius: 8px;
        background-color: ${({ theme }) => theme["bg-color-normal"]};
      }
      
      .sDash_addTask-control{
        padding-bottom: 0px;
        &.add-task-on{
          .sDash_addTask-from{
            display: block;
          }
          .btn-addTask{
            display: none;
          }
        }
        .sDash_addTask-from{
          padding: 0 20px 20px;
          display: none;
        }
        .sDash_addTask-input{
          padding: 10px;
          border-radius: 4px;
          background-color: #fff;
          width: 100%;
          height: 36px;
          border: 1px solid ${({ theme }) => theme["border-color-normal"]};
          &:focus{
            outline: none;
          }
        }
        .sDash_addTask-action{
          margin-top: 10px;
          display: flex;
          align-items: center;
          .add-column{
            margin-right: 15px;
          }
          a{
            line-height: 1;
          }
          svg,
          i{
            color: ${({ theme }) => theme["gray-color"]};
          }
        }
      }
    }
    .btn-addColumn{
      flex: 0 0 20%;
      @media only screen and (max-width: 1599px){
        flex: 0 0 25%;
      }
      @media only screen and (max-width: 1399px){
        flex: 0 0 33.333%;
      }
      @media only screen and (max-width: 767px){
        flex: 0 0 50%;
      }
      @media only screen and (max-width: 575px){
        flex: 0 0 100%;
      }
    }
    .sDash_kanban-board-item__header{
      padding: 0px 22px 1px 32px;
      background: #f4f5f7;
      border-top-left-radius: 5px;
    border-top-right-radius: 5px;
      &.editable{
        .list-header-title{
        }
        .title-edit{
          display: block;
          height: 60px;
          border-color: ${({ theme }) => theme["primary-color"]};
        }
      }
      .EditTitle-Input{
        padding: 2px 11px;
        width: 130px;
        color: #000;
        border: navajowhite;
        background: #fff;
       }
      .title-edit{
        width: 100%;
        padding: 0 15px;
        border: 1px solid #E3E6EF;
        height: 40px;
        line-height: 38px;
        border-radius: 4px;
        resize: none;
        display: none;
        transition: height .3s ease;
        &:focus{
          outline: none;
        }
      }
      .list-header-target{
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .list-header-title{
        font-size: 15px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items:center;
        margin: 0px 40px -11px -14px;
        .btn-more{
          padding: 0 4px;
          color: #868EAE;
          line-height: 1;
          position: relative;
          z-index: 22;
          svg,
          img{
            width: 20px;
            height: 20px;
            color:#5F63F2;
            transform: rotate(90deg);
            margin-left:148px;
            margin-top:14px;
          }
        }
      }
 

    }
    .sDash_kanvan-task{
      padding: 0px 8px;
    height:100%;
      
    }
    .ant-anchor-link{
      padding:0px;
    }
    .card-header{
      padding: 0px 10px 0 10px;
    }

    .sDash_kanvan-task__fotter.d-flex.justify-content-between {
      padding: 11px 7px 16px 0px;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      width:100%;
  
           .icon-calender{
            font-size:16px;
            margin-left: 14px;
            margin-top: 6px;
            color:#5F63F2;
      }
      .basket-icon{
        margin-right: 10px;
        margin-top:3px;
        font-size:16px;
        margin-top: 6px;
        color:#5F63F2;
      }

 
  }
  
    .sDash_kanvan-task__single{
      position: relative;
    background-color: #fff;
    box-shadow: 0 0 5px #9299bb10;
    border-radius: 4px;
    cursor: pointer;
    margin: 15px 0px 0px 4px;
    padding: 13px 0px 0 0px;
    max-width: 208px;
    &:first-of-type{
      margin-top:0;
    }
    &:last-of-type{
      margin-bottom:16px;
    }
    .d-flex{
      grid-column-gap: 2px;
      padding-left: 3px;
      margin-top:-2px;
      .facebook-icon{
        width: 16px;
         margin-right: 3px; 
         margin-bottom:0px;
      }
    }
      h1{
        margin-left: 4px;
        margin-bottom:0px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
        margin-top:-8px;
        width: 110px;
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;

      }
      &:hover{
        .sDash_kanvan-task__title{
          .btn-edit{
            line-height: 1;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
          }
        
         
        }
      }
      .sDash_kanvan-task__title{
        font-size: 15px;
        font-weight: 500;
        margin: 0px;
        display: flex;
        justify-content: space-between;
        align-items:baseline;
        a{
          color: ${({ theme }) => theme["gray-color"]};
          width:16px;
        }
        .btn-edit{
          display: flex;
          align-items: center;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          svg,
          img{
            width: 14px;
            height: 14px;
            color: ${({ theme }) => theme["gray-color"]};
          }
        }
      }
      .sDash_kanvan-task__edit{
        display: none;
      }
      .sDash_kanvan-task__editable{
        position: relative;
        .sDash_kanvan-task__edit{
          display: flex;
          position: absolute;
          left: -30px;
          top: -10px;
          z-index: 99999;
          width: calc(100% + 60px);
          border-radius: 4px;
          input{
            min-height: 58px;
          }
          button{
            padding: 0px 21.71px;
            height: 36px;
          }
          .sDash_kanvan-task__edit--right{
            .btn-delete{
              font-size: 12px;
              margin-left: 6px;
              white-space: nowrap;
              background-color: ${({ theme }) => theme["dark-color"]};
              line-height: 2.6;
              display: flex;
              align-items: center;
              border-radius: 5px;
              padding: 0 8px;
              color: #fff;
              i,
              svg{
                color: #fff;
                margin-right: 4px;
              }
            }
          }
        }
        .edit-kanban-task{
          margin-top: 10px;
        }
      }
    }

    .btn-addTask{
      display: inline-block;
      width: calc(100% - 40px);
      padding: 8px 0;
      text-align: center;
      border-radius: 4px;
      position: relative;
      ${({ theme }) => (theme.rtl ? "right" : "left")}: 20px;
      color: ${({ theme }) => theme["gray-color"]};
      background-color: ${({ theme }) => theme["border-color-normal"]};
      svg,
      i,
      img{
        ${({ theme }) => (theme.rtl ? "margin-left" : "margin-right")}: 3px;
      }
    }
    .btn-addColumn{
      padding: 10px;
      display:none;
      &.add-column-on{
        .btn-addColumn-inner{
          .btn-add{
            display: none;
          }
          .btn-addColumn-form{
            display: block;
          }
        }
      }
      .btn-add{
        display: block;
        width: 100%;
        height: fit-content;
        border-radius: 5px;
        padding: 12px 20px;
        background-color: #F4F5F7;
        color: ${({ theme }) => theme["gray-color"]};
        svg,
        i,
        img{
          margin-right: 3px;
        }
      }
      .btn-addColumn-inner{
        background-color: #F4F5F7;
        .sDash-add-column-input{
          padding: 10px;
          border-radius: 4px;
          background-color: #fff;
          width: 100%;
          height: 36px;
          border: 1px solid ${({ theme }) => theme["border-color-normal"]};
          &:focus{
            outline: none;
          }
        }
      }
      .btn-addColumn-form{
        display: none;
        padding: 15px 20px 15px;
        .sDash_add-column-action{
          margin-top: 10px;
          display: flex;
          align-items: center;
          .add-column{
            margin-right: 15px;
          }
          a{
            line-height: 1;
          }
          svg,
          i{
            color: ${({ theme }) => theme["gray-color"]};
          }
        }
      }
    }
  }
  .header-wrapper.d-flex.threeDotswrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #f4f5f7;
    height: 75px;
}

`;
const ChecklistWrap = Styled.div`
  .sDash_checklist-tasks__single{
    position: relative;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .35s;
    z-index: 2;
    &:before{
      position: absolute;
      ${({ theme }) => (theme.rtl ? "right" : "left")}: -12px;
      top: 0;
      width: calc(100% + 24px);
      height: 100%;
      content: '';
      background-color: #F4F5F7;
      opacity: 0;
      visibility: hidden;
      border-radius: 2px;
      z-index: -1;
    }
    &:hover{
      .btn-more{
        opacity: 1;
        visibility: visible;
      }
      &:before{
        opacity: 1;
        visibility: visible;
      }
    }
    .btn-more{
      display: flex;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      i,
      svg{
        color: #868EAE;
      }
    }
    .ant-checkbox-wrapper{
      &.ant-checkbox-wrapper-checked{
        .sDash_task-label{
          position: relative;
          display: inline-block;
          color: ${({ theme }) => theme["gray-color"]};
          &:after{
            position: absolute;
            width: 100%;
            height: 1px;
            ${({ theme }) => (theme.rtl ? "right" : "left")}: 0;
            top: 60%;
            transform: translateY(-50%);
            background-color: #9299B8;
            content: '';
          }
        }
      }
    }
  }
  .header-wrapper {
    padding: 9px 0px 25px;
    height: 0px;
}
  .sDash_checklist-tasks-wrap{
    .addChecklistTask-wrap{
      .addChecklistTask-form{
        .add-checklistTask{
          padding: 8px 10px;
          margin-bottom: 12px;
        }
        .addChecklistTask-form-action{
          display: flex;
          align-items: center;
          a{
            line-height: 1;
            svg,
            i{
              color: ${({ theme }) => theme["gray-color"]};
            }
          }
          .btn-add{
            margin-right: 10px;
            ${({ theme }) =>
    theme.rtl ? "margin-left" : "margin-right"}: 10px;
          }
        }
      }
      .add-item{
        margin-top: 15px;
      }
    }
  }
`;

const BackShadow = Styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  ${({ theme }) => (theme.rtl ? "right" : "left")};
  z-index: 9999;
  background: #00000080;
`;
const MessageModalWrapper = styled.div`
  .recipients {
    display: flex;
  }
  .add-reception-overlay-wrapper{
    position:relative ;
    .add-reception-item {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    position: absolute;
    top: -38px;
    right: 0px;
    width: 76%;
    justify-content:end ;
    .ant-select-show-arrow {
    width: 30%;
    span.ant-select-selection-item {
    color: #5F63F2;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
}
}
}
  }
  .message-alert-wrapper .ant-form-item-control-input-content label.ant-checkbox-wrapper.checkbox {
    position: relative;
    left: 0px;
    margin: 0px;
}
  .message-alert-wrapper .ant-form-item-control-input-content {
    display: flex;
    align-items: end;
    grid-gap: 8px;
}
.first-name-btn{
    display: flex;
    align-items: center;
    cursor: pointer;
    p{
      color: #5F63F2;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
      margin-left: 6px;
      bottom: 111px;
      user-select: none;
    }
}
  .range-wrapper {
    display: flex;
    justify-content: space-between;
    p {
      position: relative;
      margin-bottom: 0px;
      margin-top: 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      display: flex;
      align-items: center;

      color: #868eae;
      &:before {
        content: "";
        position: absolute;
        left: 11px;
        top: -10px;
        width: 1px;
        height: 7px;
        background-color: rgba(134, 142, 174, 0.5);
      }
      &:nth-child(1) {
        &:before {
          left: 0px;
        }
      }
      &:nth-child(10) {
        &:before {
          left: 26px;
        }
      }
    }
  }
  .ant-slider {
    margin: 0px;
  }
  .ant-slider-mark .ant-slider-mark-text {
    width: 100%;
  }
  .slider-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #272b41;
    margin-bottom: 8px;
    span {
      color: #5f63f2;
    }
  }
  .upload-btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #272b41;
    margin-bottom: 4px;
    margin-top: 24px;
  }
  .ant-slider-rail {
    background-color: rgba(134, 142, 174, 0.3);
  }

  .upload-overlay-wrapper {
    cursor: pointer;
    width: 581px;
    border: 1px dashed #e3e6ef;
    height: 108px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: relative;
    z-index: 99;
    margin-bottom: 16px;
    &:hover button {
      background-color: red;
      color: #fff !important;
    }
    .new-condition-icon {
      font-size: 50px !important;
      color: #5f63f2 !important;
      position: absolute;
      top: 10px;
    }

    .new-condition {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-top: 6px;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      color: #adb4d2;
      position: absolute;
      z-index: -3;
      top: 62px;
    }
    p {
      margin: 5px 0;
      color: #adb4d2;
      font-weight: 400;
      font-size: 14px;
    }
    button {
      background: #ddd;
      color: #000;
      border: none;
      position: absolute;
      top: 76px;
      padding: 0 7px;
      font-size: 10px;
      line-height: 0px;
      height: 22px !important;
    }
  }
  input.ant-input.upload-button {
    height: 50px !important;
    margin-bottom: 16px;
    opacity: 0;
  }
  .ant-upload-list {
    margin-top: 10px !important;
    width: 100%;
    max-height: 100px;
    height: 100%;
    overflow: auto;
    margin-bottom: 24px;
  }
  textarea.ant-input {
    border: 1px solid #d9d9d9;
    min-height: 108px;
    margin-bottom: 16px;
    font-size: 14px;
    padding: 12px 16px;
    margin-bottom: 0px;
  }
  .ant-select-selector {
    height: 36px !important;
  }
  .messages-overlay-progress {
    position: relative;
    .progress-label {
      display: flex;
      align-items: center;
      grid-gap: 6px;
      margin: 0px;
      & svg {
        color: #2fc898;
        font-size: 16px;
        &.info-icon {
          color: #5f63f2;
          font-size: 16px;
          cursor: pointer;
        }
      }
      p {
        margin-bottom: 0px;
        color: #272b41;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        display: flex;
        grid-gap: 3px;
        align-items: center;
        &.total-count {
          color: #2fc898;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
        }
      }
    }
    .progress-footer {
      p {
        font-size: 12px;
        &.total-count {
          color: #2fc898;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
        }
      }
    }
    .first-progress-b {
      .ant-progress-bg {
        background: #2fc898;
      }
    }
    .next-progress {
      margin-top: 24px;
      svg {
        color: #ff9900;
      }
      p {
        &.total-count {
          color: #ff9900;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
        }
      }
    }
    p.progress-label.progress-footer.footer-next-process {
      p {
        &.total-count {
          color: #ff9900;
        }
      }
    }
    .first-progress-c {
      .ant-progress-bg {
        background: #ff9900;
      }
    }
    .current-status {
      display: flex;
      align-items: center;
      grid-gap: 6px;
      margin-top: 24px;
      svg {
        color: #0f7acc;
        font-size: 20px;
      }
      p {
        margin-bottom: 0px;
        color: #272b41;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }
      .user-name-info {
        color: #0f7acc;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
      }
    }
    .sending-box-wrapper {
      padding: 18px;
      border: 1px dashed grey;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      text-align: center;
      p {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }
    }
    p.success-wrapper {
      display: flex;
      align-items: center;
      grid-gap: 6px;
      margin-top: 16px;
      color: #272b41;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      flex-direction: row;
      align-items: center;
      svg {
        color: #2fc898;
        font-size: 16px;
      }
      .time {
        color: #5f63f2;
      }
    }
    .success-img{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .cancel-btn-wrapper {
    background-color: #5f63f2;
    color: #fff;
    margin-left: auto;
    display: flex;
    margin-top: 16px;
    &:hover {
      border: 1px solid #5f63f2;
      background-color: transparent;
      color: #5f63f2;
    }
  }
  .success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 0px;
    flex-direction: column;

    h6 {
      color: #272b41;
      text-align: center;
      font-size: 24px;
      font-family: Inter;
      font-weight: 700;
      line-height: 30px;
      margin-top: 24px;
      margin-bottom: 0px;
    }
    p {
      color: #5a5f7d;
      text-align: center;
      font-size: 14px;
      font-family: Inter;
      line-height: 23px;
      margin-top: 12px;
    }
    .cancel-btn-wrapper {
      background-color: #5f63f2;
      color: #fff;
      display: flex;

      margin: auto;
      &:hover {
        border: 1px solid #5f63f2;
        background-color: transparent;
        color: #5f63f2;
      }
    }
    .erorr-overlay-wrapper p {
      color: #5a5f7d;
      text-align: center;
      font-size: 14px;
      line-height: 23px;
      margin: 0px;
    }
    .erorr-overlay-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 24px;
      h6 {
        color: #272b41;
        text-align: center;
        font-size: 24px;
        font-family: Inter;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 0px;
      }
      p {
        color: #5a5f7d;
        text-align: center;
        font-size: 14px;
        line-height: 23px;
        margin: 0px;
      }
      .text-erorr {
        color: #ff4d4f;
      }
    }
  }
  .divider-wrapper {
    height: 1px;
    width: 108.4%;
    background-color: #ddd;
    margin-bottom: 16px;
  }
  .success-img {
    img{
    width: 50px;
  }
  }
`;
export { KanvanBoardWrap, ChecklistWrap, BackShadow, MessageModalWrapper };
