/*global chrome*/

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import {
  Input,
  Form,
  Button as AntButton,
  Spin,
  Select,
  Upload,
  Slider,
  message,
  Progress,
  Checkbox,
  Row,
  Col,
} from "antd";
import { CloudUploadOutlined, CloseOutlined } from "@ant-design/icons";
import {
  AiFillFile,
  AiOutlineMail,
  AiOutlineInfoCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai";

import FeatherIcon from "feather-icons-react";

import { AiOutlineMessage, AiOutlineSend } from "react-icons/ai";
import { MdAccessTime } from "react-icons/md";
import { Button } from "../../components/buttons/buttons";
import { Modal } from "../../components/modals/antd-modals";
import SearchInput from "../../components/searchBar/input";
import CustomDropDownMenu from "../../components/customDropDown/CustomDropDown";
import User from "../../static/img/user.png";
import Success from "../../static/img/success.svg";
import plusIcon from "../../static/img/PlusOutlined.svg";
import Error from "../../static/img/messageError.svg";
import ErorrMessage from "../../static/img/erorr-img.png";
import { AddStageRecord, SetState, AddLeadToStage, GetStages} from "../../redux/slices/stages-slice";
import {
  GetPipelineLeads,
  SetState as SetPipelineState,
} from "../../redux/slices/pipeline-slice";
import Notification from "../ant-notification/notification";
import { MessageModalWrapper } from "./style";

import { ExtensionId, shuffleArray } from "../../utils/constants";
import { GetFolders, getSelectedTemplates } from "../../redux/slices/template-slice";

const AddNewStage = (props) => {
  let cencalmodal = true;
  const dispatch = useDispatch();
  const {
    stages: { stages, loading, success, err, stageData, stageLeads,allStagesLeads },
    pipelines: { selectedPipelineData, information, pipelinesData },
  } = useSelector((state) => state);
  const { folders } = useSelector(state => state.template);

  const ref = useRef(null);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;

  const [addStage, setAddStage] = useState(false);
  const [stageName, setStageName] = useState("");
  const [selectedStages, setSelectedStages] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState("none");
  const [sendBulkMessages, setSendBulkMessage] = useState(false);
  const [messageProgress, setMessageProgress] = useState(false);
  const [extensionMessageLoading, setExtensionMessageLoading] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [value, setValue] = useState([120, 600]);
  const [files, setFiles] = useState([]);
  const [recipients, setRecipients] = useState(0);
  const [messageDelay, setMessageDelay] = useState(0);
  const [remainingRecipients, setRemainingRecipients] = useState(0);
  const [progress, setProgress] = useState(0);
  const [messageResponse, setMessageResponse] = useState([]);
  const [moveNext, setMoveNext] = useState(true);
  const [leadIds, setLeadIds] = useState([]);
  const [filter, setFilter] = useState({ keyword: '' });
  const [sort, setSort] = useState([["createdAt", "ascending"]]);
  useEffect(() => {
    setAddStage(false);
    setStageName("");
    if (err) {
      Notification({
        type: "error",
        message: err,
      });
      dispatch(SetState({ field: "err", value: "" }));
      setExtensionMessageLoading(false);
      setSendBulkMessage(false);
      setMessageData("");
      setSelectedStages([]);
    }
    if (information) {
      Notification({
        type: "success",
        message: information,
      });
      setExtensionMessageLoading(false);
      setSendBulkMessage(false);
      setMessageData("");
      cons
      setSelectedStages([]);
      dispatch(SetPipelineState({ field: "information", value: "" }));
    }
    if (stageData && !isEmpty(stageData)) {
      const newStages = [...stages, stageData];
      dispatch(SetState({ field: "stages", value: newStages }));
      dispatch(SetState({ field: "stageData", value: null }));
    }

    if (messageDelay) {
      const timer = setInterval(() => {
        setMessageDelay(prevSeconds => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [success, err, stageData, information, messageDelay]);

  useEffect(() => {
    (async () => {
      await selectStagesHandler(selectedStages);
    })();
  }, [selectedStages]);

  useEffect(() => {
    dispatch(GetFolders({ filter, sort }));
  }, [])

  useEffect(() => {
    (async () => {
      await getTemplatesOfSelectedFolders(selectedFolders)
    })();
  }, [selectedFolders]);
  const addStageData = async (stageName) => {
    if (!stageName || !stageName.trim()) {
      return Notification({
        type: "warning",
        message: "Name Is Not Provided",
      });
    }
    dispatch(
      AddStageRecord({
        stageName: stageName.trim(),
        pipelineId: selectedPipelineData,
      })
    );
  };

  const addToTextArea = function (text_to_add) {
    let textArea = ref.current?.resizableTextArea?.textArea

    let start_position = textArea?.selectionStart;
    let end_position = textArea?.selectionEnd;

    let message = `${textArea?.value.substring(
      0,
      start_position
    )}${text_to_add}${textArea?.value.substring(
      end_position,
      textArea?.value.length
    )}`;
    setMessageData(message);
  };
  const getTemplatesOfSelectedFolders = async (selectedFolders) => {
    const response = await dispatch(getSelectedTemplates({ selectedFolders }));
    if (response?.payload?.data) {
      setTemplates(response?.payload?.data)
    }
  }
  const addToNextPipeline = async (leadIds) => {

    const leadStage = allStagesLeads?.filter((stage) => stage.leadId === leadIds);
    const currentIndex = stages.findIndex((el) => el._id === leadStage[0]?.stageId);
    const nextIndex = currentIndex + 1;
    if (nextIndex < stages.length) {
      let pipelineId = selectedPipelineData;
      let stageId = stages[nextIndex]?._id;
      let leadId = leadIds;
      await dispatch(AddLeadToStage({ pipelineId, stageId, leadId }));

    }

  }
  const handleReplaceLeadsName = ({ profiles }) => {
    let lastSelectedTemplate;
    return profiles.map((lead) => {
      let message;
      if (selectedFolders === "none") {
        console.log("selected folder is none so message is ", messageData);
        message = messageData;
      } else {
        const selectedTemplate = templates.filter(
          (template) => template.folderId === selectedFolders,
        );
        console.log("selected template is ", selectedTemplate);
        
        if (!selectedTemplate.length) {
          alart("No template found for the selected folder");
          return;
        }
        
        // select one random message from the selected template
        // const randomMessage = selectedTemplate[getRandomInt(0, selectedTemplate.length - 1)];
        const randomMessage = shuffleArray(
          shuffleArray(
            shuffleArray(
              selectedTemplate.filter((template) => template !== lastSelectedTemplate),
            ),
          ),
        )[0];
        lastSelectedTemplate = randomMessage;
        message = randomMessage?.message;
        
        console.log("selected message is ", message);
      }

      if (message?.includes("{first_name}") || message?.includes("{last_name}") || message?.includes("{name}")) {
        const nameArray = lead.name.trim().split(" ");
  
        let firstName = nameArray[0];
        let lastName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : firstName;
        let fullName = lead.name;
  
        if (firstName.length === 1 && nameArray[1]) {
          firstName = nameArray[0] + nameArray[1];
        }
  
        message = message.replace(/\{name\}/g, fullName);
        message = message.replace(/\{first_name\}/g, firstName);
        message = message.replace(/\{last_name\}/g, lastName);
  
        return { leadId: lead.leadId, message, name: lead.name, _id: lead._id };
      } else {
        return { leadId: lead.leadId, message: message, name: lead.name, _id: lead._id };
      }
    });
  };

  const convertFiles = (files) => {
    return new Promise((resolve) => {
      if (files && files.length > 0) {
        let imagePayloads = [];
        let loadedCount = 0;

        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          let reader = new FileReader();

          reader.onloadend = (function (file) {
            return function () {
              imagePayloads.push(reader.result);
              loadedCount++;

              if (loadedCount === files.length) {
                resolve(imagePayloads);
              }
            };
          })(file);

          reader.readAsDataURL(file);
        }
      } else {
        resolve([]);
      }
    });
  };
  const RemoveFileHandler = () => {
    setFiles([]);
  };

  const handleFileUpload = (e) => {
    let images = [];
    const uploadedFiles = e.target.files;
    for (let i = 0; i < uploadedFiles.length; i++) {
      images.push(uploadedFiles[i]);
    }
    setFiles([...images]);
  };
  const delay = async (ms) => new Promise((res) => setTimeout(res, ms));

  const getRandomInt = (min, max) => {
    var min = Number(min);
    var max = Number(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleSendBulkMessages = async () => {
    if (!chrome || !chrome.runtime)
      return dispatch(
        SetState({ field: "err", value: "Extension Is Not Installed" })
      );
    const response = await dispatch(
      GetPipelineLeads({
        pipelineId: selectedPipelineData,
        stageIds: JSON.stringify(selectedStages),
      })
    );
    const profiles = response?.payload?.data;
    const name = pipelinesData.find((data) => data._id === selectedPipelineData)
      ?.name;
    if (!profiles?.length)
      return dispatch(SetState({ field: "err", value: "Leads Dont Exist" }));
    const messagesList = handleReplaceLeadsName({ profiles });
    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "IS_EXTENSION_INSTALLED",
      },
      async (response) => {
        if (response && response.success) {
          chrome.runtime?.sendMessage(
            ExtensionId,
            {
              messageType: "CHECK_LOGGED_IN",
            },
            async ({ loggedIn }) => {
              if (loggedIn) {
                if (response?.isUserValid === false)
                  return dispatch(
                    SetState({
                      field: "err",
                      value: "This Facebook Account Is Not Attached",
                    })
                  );

                if (recipients > 0) {
                  setMessageProgress(true);
                }
                const images = await convertFiles(files);
                let idsList = [...leadIds];

                for (let i = 0; i < messagesList.length; i++) {
                  const newObject = { name: messagesList[i].name, success: "loading" };
                  await new Promise((resolve) => {
                    setMessageDelay(0);
                    setMessageResponse(newObject);
                    chrome.runtime?.sendMessage(
                      ExtensionId,
                      {
                        messageType: "Send_Bulk_Messages",
                        profiles: messagesList[i],
                        name,
                        images,
                      },
                      async (data) => {
                        const { person, status } = data.message;
                        setMessageResponse({ name: person, success: status });
                        setProgress((prevState) => prevState + 1);
                        setRemainingRecipients((prevState) => prevState - 1);
                        if (moveNext && status === "success") {
                          await addToNextPipeline(messagesList[i]._id)
                          dispatch(SetState({ field: 'refreshStage', value: true }));
                        }
                        resolve();
                      }
                    );
                  });
                  if (i !== messagesList.length - 1) {
                    const randomNumber = getRandomInt(value[0], value[1]);
                    setMessageDelay(Math.floor(randomNumber));
                    await delay(randomNumber * 1000);
                  }
                  if (i === messagesList.length - 1) {
                    window.onbeforeunload = null;
                  }
                }
              } else {
                return dispatch(
                  SetState({ field: "err", value: "Please Login To Facebook" })
                );
              }
            }
          );
        } else
          return dispatch(
            SetState({ field: "err", value: "Extension Is Not Installed" })
          );
      }
    );
  };

  const selectStagesHandler = async (selectedStages) => {
    const response = await dispatch(
      GetPipelineLeads({
        pipelineId: selectedPipelineData,
        stageIds: JSON.stringify(selectedStages),
      })
    );

    const profiles = response?.payload?.data;
    if (profiles) {
      setRecipients(profiles?.length);
      setRemainingRecipients(profiles?.length);
    }
  };

  return (
    <>
      <Button
        outlined
        className="pipeline-header-btn"
        size="default"
        type="primary"
        onClick={() => {
          setAddStage(true);
        }}
      >
        <FeatherIcon icon="plus" />
        Add New Stage
      </Button>

      <Button
        outlined
        disabled={!stageLeads.length}
        className="pipeline-header-btn"
        size="default"
        type="primary"
        onClick={() => {
          (async () => {
            await selectStagesHandler(selectedStages);
          })();
          setSendBulkMessage(true);
          window.onbeforeunload = function () {
            return "";
          };
        }}
      >
        <AiOutlineMessage icon="plus" />
        Send Messages
      </Button>
      <MessageModalWrapper>
        <Modal
          className="Create New Pipeline create-new-pipeline-modal Messages-wrapper"
          footer={null}
          type="primary"
          title="Send Message"
          centered
          visible={sendBulkMessages}
          width={628}
          closable={true}
          onCancel={() => {
            setExtensionMessageLoading(false);
            setSendBulkMessage(false);
            setMessageData("");
            window.onbeforeunload = null;
          }}
        >
          <MessageModalWrapper>
            <Form form={form} layout="vertical">
              <Form.Item
                className="select-stages-wrapper"
                label={
                  <p className="label-wrappers">
                    Select Stage
                    <span>(All Stages Will Be Selected By Default)</span>
                  </p>
                }
              >
                <div className="tags-select list-select-wrapper">
                  <Select
                    mode="multiple"
                    allowClear
                    value={selectedStages}
                    placeholder="Select Stage Here  "
                    className="select-drop-down add-tags-wrapper"
                    onChange={(event) => {
                      setSelectedStages(event);
                    }}
                  >
                    {stages?.map((stage, index) => (
                      <Option key={stage._id}>{stage.name}</Option>
                    ))}
                  </Select>
                </div>

                <div className="recipients">
                  <p>Sending messages to: </p>
                  <p>{recipients}</p>
                </div>
              </Form.Item>
              <Form.Item
                className="select-folder-wrapper"
                label={
                  <p className="label-wrappers">
                    Select Message Group
                  </p>
                }
              >
                <div className="tags-select list-select-wrapper">
                  <Select
                    allowClear
                    placeholder=" Select Message Group Here "
                    className="select-drop-down add-tags-wrapper"
                    value={selectedFolders}
                    onChange={(event) => {
                      setSelectedFolders(event)
                      // clearing out the message data when selecting a message group
                      event !== 'none' && setMessageData('');
                    }}
                  >
                    <Option key="none">None</Option>
                    {folders?.map((folder, index) => (
                      <Option key={folder._id}>{folder.name}</Option>
                    ))}
                  </Select>
                </div>
              </Form.Item>
              <div
                className="add-reception-overlay-wrapper"
                style={{ display: selectedFolders === "none" ? "block" : "none" }}
              >
                <Form.Item
                  className="message-Box"
                  label={<p className="label-wrappers">Message Details</p>}
                >
                  <TextArea
                    ref={ref}
                    className="border-radius-none"
                    value={messageData}
                    size={"large"}
                    rows={5}
                    onChange={(event) => {
                      setMessageData(event.target.value);
                    }}
                    placeholder='{"{Hello|Hi|Hey} {friend|buddy|mate}, how are you {doing|feeling} today?"}'
                  />

                  {/* <div className="add-reception-item">
                    <Select
                      style={{width: "170px"}}
                      allowClear
                      placeholder="Select Group Message"
                      onChange={(event) => {
                        setMessageData(event);
                      }}
                    >
                      {templates?.map((template, index) => (
                        <Option key={template?.message}>{template?.name}</Option>
                      ))}
                    </Select>
                    <div className="first-name-btn" onClick={() => {
                      addToTextArea(' {name} ')
                    }}><img src={plusIcon} /> <p>Add Recipient Name</p></div>
                  </div> */}
                </Form.Item>
                <div className="d-flex" style={{"margin-bottom": "12px"}}>
                  <AntButton onClick={() => addToTextArea(" {first_name} ")} type="default" size="small">
                    {"{first_name}"}
                  </AntButton>
                  <AntButton onClick={() => addToTextArea(" {last_name} ")} size="small">
                    {"{last_name}"}
                  </AntButton>
                  <AntButton onClick={() => addToTextArea(" {name} ")} size="small">
                    {"{name}"}
                  </AntButton>
                </div>
              </div>
              <Form.Item
                className="message-alert-wrapper"
              >
                <Checkbox
                  checked={moveNext}
                  onChange={(event) => {
                    setMoveNext(event.target.checked)
                  }}
                  className="checkbox"
                  type="checkbox">
                    Move the leads to the next stage upon  message sending
                </Checkbox>
              </Form.Item>
            </Form>

          </MessageModalWrapper>


          <MessageModalWrapper>
            <p className="slider-label">
              {/* Delay Between Messages: <span>15 to 55 Seconds</span> */}
              Delay Between Messages: &nbsp;
              <span>
                {value?.length > 0 ? `${value[0]} to ${value[1]}` : "15 to 55 "}
                Seconds
              </span>
            </p>
            <Slider
              style={{
                paddingBottom: "20px",
                marginLeft: "10px",
                marginRight: "16px",
              }}
              min={120}
              max={600}
              marks={{
                120: "2 min",
                180: "3 min",
                240: "4 min",
                300: "5 min",
                360: "6 min",
                420: "7 min",
                480: "8 min",
                540: "9 min",
                600: "10 min",
              }}
              defaultValue={value}
              range
              step={60}
              onChange={setValue}
            />
            <p className="upload-btn">Upload Images</p>
            {files.length ? (
              <div
                onClick={RemoveFileHandler}
                className="upload-overlay-wrapper"
              >
                <AiFillFile style={{ color: "#5f63f2", fontSize: "20px" }} />
                <p>{files?.length} files</p>
                <Button>Remove File</Button>
              </div>
            ) : (
              <div className="upload-overlay-wrapper">
                <CloudUploadOutlined className="new-condition-icon" />
                <p className="new-condition">
                  Click to add file on this area to upload
                </p>
                <Input
                  type="file"
                  className="upload-button"
                  multiple
                  accept="image/png, image/jpeg"
                  onChange={handleFileUpload}
                />
              </div>
            )}
          </MessageModalWrapper>
          <div className="modal-footer d-flex justify-content-end">
            <Button
              size="default"
              outlined
              type="white"
              onClick={() => {
                setExtensionMessageLoading(false);
                setSendBulkMessage(false);
                setMessageData("");
                window.onbeforeunload = null;
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={(selectedFolders === 'none' && !messageData)}
              onClick={() => {
                handleSendBulkMessages();
                setExtensionMessageLoading(false);
                setSendBulkMessage(false);
                setMessageData("");
              }}
              size="default"
              type="primary"
              className="ml-2 Edit-list-pipeline-btn"
            >
              {extensionMessageLoading ? (
                <Spin size="small" style={{ color: "#FFFFFF" }} />
              ) : (
                "Send Message"
              )}
            </Button>
          </div>
        </Modal>
      </MessageModalWrapper>

      <Modal
        className="Create New Pipeline create-new-pipeline-modal"
        footer={null}
        type="primary"
        title="Add Stage"
        centered
        visible={addStage}
        width={628}
        closable={true}
        onCancel={() => {
          setAddStage(false);
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Title">
            <Input
              value={stageName}
              onChange={(event) => {
                setStageName(event.target.value);
              }}
              placeholder="Stage Name Here"
            />
          </Form.Item>
        </Form>
        <div className="modal-footer d-flex justify-content-end">
          <Button
            size="default"
            outlined
            type="white"
            onClick={() => {
              setStageName("");
              setAddStage(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              addStageData(stageName);
            }}
            size="default"
            type="primary"
            disabled={!stageName.length || loading ? true : false}
            className="ml-2 create-stage-btn"
          >
            {loading ? <Spin size="small" /> : "Create"}
          </Button>
        </div>
      </Modal>
      <MessageModalWrapper>
        <Modal
          className="Create New Pipeline create-new-pipeline-modal Messages-wrapper"
          footer={null}
          type="primary"
          title="Send Message"
          centered
          visible={messageProgress}
          width={628}
          closable={true}
        // onCancel={() => {
        // setMessageProgress(false);
        // window.onbeforeunload = null;
        // }}
        >
          <MessageModalWrapper>
            {remainingRecipients > 0 ?
              <>
                <div className="messages-overlay-progress">
                  <p className="progress-label"><AiOutlineMail /><p>Number of Messages to Send:</p><p className="total-count">{recipients}</p></p>
                  <p className="progress-label next-progress"><MdAccessTime /><p>Remaining Messages to Send:</p><p className="total-count">{remainingRecipients}</p></p>
                  <Progress className="first-progress-c" percent={Math.round(progress / recipients * 100)} />
                  <p className="progress-label progress-footer footer-next-process"><p>Remaining Users: <p className="total-count">{remainingRecipients + '/' + recipients}</p></p></p>

                  <p className="current-status"><AiOutlineSend /><p>Currently Sending Message to:</p><p className="user-name-info">{messageResponse?.name}</p></p>
                  <div className="sending-box-wrapper">
                    {messageResponse?.success === "loading" ? <> <Spin size="large" />
                      <p>Sending Message to {messageResponse?.name}...</p></> : messageResponse?.success === "success" ? <div className="success-img"><img src={Success} /></div> : <div className="success-img"><img src={Error} /></div>}
                  </div>
                  {messageDelay > 0 ? <p className="success-wrapper"><AiOutlineCheckCircle />Success: Next message to be sent in <span className="time">{messageDelay} seconds.</span></p> : ''}
                </div>
              </> :

              <div className="success-wrapper">
                <img src={Success} />
                <h6>Messages Successfully Sent!</h6>
                <p>Your messages have been successfully sent to all users.</p>
                <Button className="cancel-btn-wrapper" onClick={async () => {
                  setMessageProgress(false);
                  window.onbeforeunload = null;
                  setSelectedStages([]);
                  setProgress(0);
                  setMoveNext(true)

                }}
                >Ok</Button>
              </div>}
          </MessageModalWrapper>
        </Modal>
      </MessageModalWrapper>
    </>
  );
};

export default AddNewStage;
