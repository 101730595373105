import React, { useState } from 'react';
import { Divider, Menu } from 'antd';
import { useSelector } from "react-redux";
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import { AiOutlineHome, AiOutlineContainer } from "react-icons/ai"
import { BsCardText } from "react-icons/bs"
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import Title from 'antd/lib/typography/Title';

const getMainPath = (path) => {
  if (!path || !path.length) return ['dashboard'];
  const defaultPath = path[0];
  if (defaultPath === 'pipeline-stages') {
    return ['pipelines'];
  } else if (defaultPath === 'blocked-leads') {
    return ['blocked-leads']
  } else return path
}

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const { role } = useSelector(state => state.auth);
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    setOpenKeys(mainPathSplit);
    history.push(item.key);
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const defaultPath = getMainPath(mainPathSplit);

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={defaultPath}
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item className='home-menu'
        icon={
          !topMenu && (
            <AiOutlineHome className='pipeline-icon' />
          )
        }
        key={"dashboard"}
      >
        <NavLink className="dashboard-link-wrapper"
        key={"dashboard-1"}
        to={`${path}dashboard`}>
          Dashboard
        </NavLink>
      </Menu.Item>
      <Menu.Item
        icon={
          !topMenu && (
            <FeatherIcon icon="users" />
          )
        }
        key={"leads"}
      >

        <NavLink to={`${path}leads`}>
          Leads
        </NavLink>
      </Menu.Item>
      <Menu.Item
        className='pipeline-li'
        icon={
          !topMenu && (
            <FeatherIcon className="pipeline-icon" icon="bar-chart-2" />
          )
        }
        key={"pipelines"}
      >
        <NavLink to={`${path}pipelines`} className="pipeline-link">
          Pipelines
        </NavLink>
      </Menu.Item>

      <Menu.Item
        className='list-li'
        icon={
          !topMenu && (
            <FeatherIcon className="list-icon" icon="list" />
          )
        }
        key={"lists"}
      >
        <NavLink to={`${path}lists`} className="list-link">
          Lists
        </NavLink>
      </Menu.Item>

      <Menu.Item
        className='blocked-li'
        icon={
          !topMenu && (
            <FeatherIcon className="blocked-icon" icon="lock" />
          )
        }
        key={"blocked-leads"}
      >
        <NavLink to={`${path}blocked-leads`} className="blocked-link">
          Blocked Leads
        </NavLink>
      </Menu.Item>

      <Menu.Item
        className='groups-post-li'
        icon={
          !topMenu && (
            <BsCardText className='' style={{width: "16px", height: "24px"}} />
          )
        }
        key={"groups-posts"}
      >
        <NavLink to={`${path}groups-posts`} className="groups-link">
          Groups & Posts
        </NavLink>
      </Menu.Item>
      <Menu.Item
        className='groups-post-li'
        icon={
          !topMenu && (
            <AiOutlineContainer style={{width: "16px", height: "24px"}} />
          )
        }
        key={"messaging"}
      >
        <NavLink to={`${path}messaging`} className="groups-link">
          Messaging
        </NavLink>
      </Menu.Item>
      <Menu.Item
        className='blocked-li'
        icon={
          !topMenu && (
            <FeatherIcon className="blocked-icon" icon="lock" />
          )
        }
        key={"affiliate"}
      >
        <a href={"https://fbsocialninja.firstpromoter.com/"} target="_blank" className="blocked-link">
          Affiliate Program
        </a>
      </Menu.Item>
      {role && role === 'admin' &&
        <>
          <Divider />
          <Title level={4} className='home-menu-title'>
            Admin
          </Title>
          <Menu.Item
            className=''
            icon={
              !topMenu && (
                <FeatherIcon icon="users" />
              )
            }
            key={"chatpro-users"}
          >
            <NavLink className="dashboard-link-wrapper" to={`${path}chatpro-users`}>
              Users
            </NavLink>
          </Menu.Item>
        </>
      }
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
